<template>
  <div>
    <div class="print-main-css" :style="'height: ' + dialogHeight + 'px'" v-show="false">
      <div style="padding:0 65px;width:780px;background-color: white" id="myDeclareDetailPrint">
        <div class="ms-card-print">
          <div style="page-break-after: always;padding-top: 30px">
            <div slot="header" style="text-align: center;border-bottom:none;" class="ms-card-print-sub">
              <span class="ms-card-title" style="color: #000000;font-size: 16px;font-weight: bold;">职业病危害项目申报表</span>
            </div>
            <div class="ms-declare-detail ms-card-print-sub">
              <div>
                <div style="border-bottom: 1px solid #000000">
                  <table class="ms-declare-detail-print-table">
                    <tr>
                      <td class="ms-print-header" colspan="6">
                        基本信息
                      </td>
                    </tr>
                    <tr>
                      <td width="140px" class="ms-declare-print-label">
                        <div>申报类型：</div>
                      </td>
                      <td width="80px" class="ms-declare-print-item">
                        <div>
                          <span v-if="msDeclarationPrintViewForm.declarationExp.declareType === 0">初次申报</span>
                          <span v-else-if="msDeclarationPrintViewForm.declarationExp.declareType === 1">变更申报</span>
                          <span v-else-if="msDeclarationPrintViewForm.declarationExp.declareType === 2">年度更新</span>
                        </div>
                      </td>
                      <td width="110px" class="ms-declare-print-label">
                        <div>申报年份：</div>
                      </td>
                      <td width="110px" class="ms-declare-print-item">
                        <div>{{ msDeclarationPrintViewForm.declarationExp.declareYear }}</div>
                      </td>
                      <td width="80px" class="ms-declare-print-label">
                        <div>申报日期：</div>
                      </td>
                      <td class="ms-declare-print-item ">
                        <div>
                          {{ msDeclarationPrintViewForm.declarationExp.declareDate }}
                        </div>
                      </td>
                    </tr>
                    <tr v-if="msDeclarationPrintViewForm.declarationExp.declareType === 1 && msDeclarationPrintViewForm.declarationExp.reasonNo === '20030099'">
                      <td class="ms-declare-print-label">
                        <div>变更原因：</div>
                      </td>
                      <td class="ms-declare-print-item">
                        <div>
                          {{ msDeclarationPrintViewForm.declarationExp.reasonName }}
                        </div>
                      </td>
                      <td class="ms-declare-print-label">
                        <div>请注明：</div>
                      </td>
                      <td colspan="3" class="ms-declare-print-item ">
                        <div>{{ msDeclarationPrintViewForm.declarationExp.remark }}</div>
                      </td>
                    </tr>
                    <tr v-if="msDeclarationPrintViewForm.declarationExp.declareType === 1 && msDeclarationPrintViewForm.declarationExp.reasonNo !== '20030099'">
                      <td class="ms-declare-print-label">
                        <div>变更原因：</div>
                      </td>
                      <td colspan="5" class="ms-declare-print-item">
                        <div>
                          {{ msDeclarationPrintViewForm.declarationExp.reasonName }}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="ms-declare-print-label">
                        <div>单位名称：</div>
                      </td>
                      <td colspan="2" class="ms-declare-print-item">
                        <div>{{ msDeclarationPrintViewForm.declarationExp.unitName }}</div>
                      </td>
                      <td class="ms-declare-print-label">
                        <div>统一社会信用代码：</div>
                      </td>
                      <td class="ms-declare-print-item" colspan="2">
                        <div>{{ msDeclarationPrintViewForm.declarationExp.creditCode }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td class="ms-declare-print-label">
                        <div>单位注册地址：</div>
                      </td>
                      <td colspan="5" class="ms-declare-print-item">
                        <div>{{ msDeclarationPrintViewForm.declarationExp.regAddr }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td class="ms-declare-print-label">
                        <div>作业场所地址：</div>
                      </td>
                      <td colspan="5" class="ms-declare-print-item">
                        <div>{{ msDeclarationPrintViewForm.declarationExp.workAddr }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td class="ms-declare-print-label">
                        <div>企业规模：</div>
                      </td>
                      <td class="ms-declare-print-item" colspan="2">
                        <div>
                          <span v-if="msDeclarationPrintViewForm.declarationExp.enterpriseScale === 1">大型企业</span>
                          <span v-else-if="msDeclarationPrintViewForm.declarationExp.enterpriseScale === 2">中型企业</span>
                          <span v-else-if="msDeclarationPrintViewForm.declarationExp.enterpriseScale === 3">小型企业</span>
                          <span v-else-if="msDeclarationPrintViewForm.declarationExp.enterpriseScale === 4">微型企业</span>
                        </div>
                      </td>
                      <td class="ms-declare-print-label">
                        <div>经济类型：</div>
                      </td>
                      <td class="ms-declare-print-item" colspan="2">
                        <div>
                          {{ msDeclarationPrintViewForm.declarationExp.economicName }}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="ms-declare-print-label">
                        <div>行业分类：</div>
                      </td>
                      <td class="ms-declare-print-item " colspan="5">
                        <div>
                          {{ msDeclarationPrintViewForm.declarationExp.industryCateName }}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="ms-declare-print-label">
                        <div>填报人：</div>
                      </td>
                      <td class="ms-declare-print-item" colspan="2">
                        <div>{{ msDeclarationPrintViewForm.declarationExp.fillMan }}</div>
                      </td>
                      <td class="ms-declare-print-label">
                        <div>联系电话：</div>
                      </td>
                      <td class="ms-declare-print-item" colspan="2">
                        <div>{{ msDeclarationPrintViewForm.declarationExp.fillPhone }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td class="ms-declare-print-label">
                        <div>法定代表人：</div>
                      </td>
                      <td class="ms-declare-print-item " colspan="2">
                        <div>{{ msDeclarationPrintViewForm.declarationExp.legalPerson }}</div>
                      </td>
                      <td class="ms-declare-print-label">
                        <div>联系电话：</div>
                      </td>
                      <td class="ms-declare-print-item" colspan="2">
                        <div>{{ msDeclarationPrintViewForm.declarationExp.legalPersonPhone | dealNullOnly }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td class="ms-declare-print-label">
                        <div>职业卫生管理联系人：</div>
                      </td>
                      <td class="ms-declare-print-item " colspan="2">
                        <div>{{ msDeclarationPrintViewForm.declarationExp.linkManager }}</div>
                      </td>
                      <td class="ms-declare-print-label">
                        <div>手机号码：</div>
                      </td>
                      <td class="ms-declare-print-item " colspan="2">
                        <div>{{ msDeclarationPrintViewForm.declarationExp.linkPhone }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td class="ms-declare-print-label">
                        <div>本单位在册职工总数：</div>
                      </td>
                      <td class="ms-declare-print-item">
                        <div>{{ msDeclarationPrintViewForm.declarationExp.empNum }}</div>
                      </td>
                      <td class="ms-declare-print-label">
                        <div>外委人员总数：</div>
                      </td>
                      <td class="ms-declare-print-item">
                        <div>{{ msDeclarationPrintViewForm.declarationExp.externalNum }}</div>
                      </td>
                      <td class="ms-declare-print-label">
                        <div>在册接害人数：</div>
                      </td>
                      <td class="ms-declare-print-item">
                        <div>{{ msDeclarationPrintViewForm.declarationExp.victimsNum - msDeclarationPrintViewForm.declarationExp.outSourcingNum }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td class="ms-declare-print-label">
                        <div>外委接害人数：</div>
                      </td>
                      <td class="ms-declare-print-item ">
                        <div>{{ msDeclarationPrintViewForm.declarationExp.outSourcingNum }}</div>
                      </td>
                      <td class="ms-declare-print-label">
                        <div>接害总人数(含外委)：</div>
                      </td>
                      <td class="ms-declare-print-item">
                        <div>{{ msDeclarationPrintViewForm.declarationExp.victimsNum }}</div>
                      </td>
                      <td class="ms-declare-print-label">
                        <div>职业病累计人数：</div>
                      </td>
                      <td class="ms-declare-print-item ">
                        <div>{{ msDeclarationPrintViewForm.declarationExp.occupationalDiseasesNum }}</div>
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="ms-declare-detail-print-table">
                  <table class="ms-declare-detail-print-table print-border-bottom">
                    <tr>
                      <td class="ms-print-header" colspan="6">
                        职业卫生培训情况
                      </td>
                    </tr>
                    <tr>
                      <td width="120px" class="ms-declare-print-label">
                        <div>主要负责人培训：</div>
                      </td>
                      <td width="50px" class="ms-declare-print-item print-center">
                        <span v-if="msDeclarationPrintViewForm.declarationExp.existsLeaderTrain">已培训</span>
                        <span v-else>未培训</span>
                      </td>
                      <td width="140px" class="ms-declare-print-label">
                        <div>职业卫生管理人员培训：</div>
                      </td>
                      <td width="50px" class="ms-declare-print-item print-center">
                        <span v-if="msDeclarationPrintViewForm.declarationExp.existsManagersTrain">已培训</span>
                        <span v-else>未培训</span>
                      </td>
                    </tr>
                    <tr>
                      <!-- <td width="120px" class="ms-declare-print-label">
                        <div>接害应培训人数：</div>
                      </td>
                      <td class="ms-declare-print-item print-center">
                        {{ msDeclarationPrintViewForm.declarationExp.shouldTrainingNum }}
                      </td>
                      <td width="140px" class="ms-declare-print-label">
                        <div>接害实培训人数：</div>
                      </td>
                      <td class="ms-declare-print-item print-center">
                        {{ msDeclarationPrintViewForm.declarationExp.actualTrainingNum }}
                      </td> -->
                      <td width="210px" class="ms-declare-print-label">
                        <div>接触职业病危害因素年度培训总人数：</div>
                      </td>
                      <td class="ms-declare-print-item print-center">
                        {{ msDeclarationPrintViewForm.declarationExp.trainSum }}
                      </td>
                      <td width="210px" class="ms-declare-print-label">
                        <div>接害人数培训率：</div>
                      </td>
                      <td class="ms-declare-print-item print-center">
                        {{ msDeclarationPrintViewForm.declarationExp.totalTraineeRate }}
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="ms-declare-detail-print-table">
                  <table class="ms-declare-detail-print-table">
                    <tr>
                      <td class="ms-print-header" colspan="4">
                        主要产品
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 130px;!important;" class="ms-declare-print-label">
                        <div>军工等涉密产品：</div>
                      </td>
                      <td style="width:unset" class="ms-declare-print-item print-left" colspan="3">
                        <span v-if="msDeclarationPrintViewForm.declarationExp.ifClassifiedUnit">是</span>
                        <span v-else>否</span>
                      </td>
                    </tr>
                    <tr v-for="item in msDeclarationPrintViewForm.majorProductList">
                      <td style="width: 130px;!important;" class="ms-declare-print-label">
                        <div>产品名称：</div>
                      </td>
                      <td style="width: 180px;!important;" class="ms-declare-print-item">
                        <div>{{ item.productName }}</div>
                      </td>
                      <td style="width: 80px;!important;" class="ms-declare-print-label">
                        <div>年产量：</div>
                      </td>
                      <td style="width:unset" class="ms-declare-print-item">
                        <div>{{ item.dosage }}&nbsp;{{ item.unit }}</div>
                      </td>
                    </tr>
                    <template v-if="msDeclarationPrintViewForm.declarationExp.declareType === 1 && msDeclarationPrintViewForm.majorProductList !== undefined && msDeclarationPrintViewForm.majorProductList.length < 11">
                      <template v-for="count in 11 - msDeclarationPrintViewForm.majorProductList.length">
                        <tr>
                          <td style="width: 130px;!important;" class="ms-declare-print-label">
                            <div>产品名称：</div>
                          </td>
                          <td style="width: 180px;!important;" class="ms-declare-print-item">/</td>
                          <td style="width: 80px;!important;" class="ms-declare-print-label">
                            <div>年产量：</div>
                          </td>
                          <td style="width: unset;" class="ms-declare-print-item">/</td>
                        </tr>
                      </template>
                    </template>
                    <template v-if="msDeclarationPrintViewForm.declarationExp.declareType !== 1 && msDeclarationPrintViewForm.majorProductList !== undefined && msDeclarationPrintViewForm.majorProductList.length < 12">
                      <template v-for="count in 12 - msDeclarationPrintViewForm.majorProductList.length">
                        <tr>
                          <td style="width: 130px;!important;" class="ms-declare-print-label">
                            <div>产品名称：</div>
                          </td>
                          <td style="width: 180px;!important;" class="ms-declare-print-item">/</td>
                          <td style="width: 80px;!important;" class="ms-declare-print-label">
                            <div>年产量：</div>
                          </td>
                          <td style="width: unset" class="ms-declare-print-item">/</td>
                        </tr>
                      </template>
                    </template>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="padding-top: 30px">
          <div class="ms-declare-detail ms-card-print-sub">
            <div style="border-bottom: 1px solid #000000">
              <table class="ms-declare-detail-print-table">
                <tr>
                  <td class="ms-print-header" colspan="8">
                    职业病危害因素种类
                  </td>
                </tr>
                <tr>
                  <td width="95px" class="ms-declare-print-label" :rowspan="computeRowspan(msDeclarationPrintViewForm.dustDetailList.length)">
                    <div>粉尘：</div>
                  </td>
                  <td width="45px" class="ms-declare-print-item" :rowspan="computeRowspan(msDeclarationPrintViewForm.dustDetailList.length)">
                    <div v-if="msDeclarationPrintViewForm.distributionExp.existsDust">有</div>
                    <div v-else-if="!msDeclarationPrintViewForm.distributionExp.existsDust">无</div>
                  </td>
                  <td width="170px" class="ms-declare-print-label">接触总人数：</td>
                  <td colspan="3" class="ms-declare-print-item">
                    <div>{{ msDeclarationPrintViewForm.distributionExp.dustContacts }}</div>
                  </td>
                </tr>
                <tr v-for="(item, index) in msDeclarationPrintViewForm.dustDetailList" v-if="index % 2 == 0">
                  <td width="160px" class="ms-declare-print-label">
                    <div>{{ msDeclarationPrintViewForm.dustDetailList[index].hazardsName }}接触人数：</div>
                  </td>
                  <td width="60px" class="ms-declare-print-item">
                    <div>{{ msDeclarationPrintViewForm.dustDetailList[index].contactNumber }}</div>
                  </td>
                  <td width="160px" class="ms-declare-print-label">
                    <div v-if="msDeclarationPrintViewForm.dustDetailList[index + 1] != undefined">
                      {{ msDeclarationPrintViewForm.dustDetailList[index + 1].hazardsName + '接触人数：' }}
                    </div>
                  </td>
                  <td width="60px" class="ms-declare-print-item">
                    <div v-if="msDeclarationPrintViewForm.dustDetailList[index + 1] != undefined">
                      {{ msDeclarationPrintViewForm.dustDetailList[index + 1].contactNumber }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="ms-declare-print-label" :rowspan="computeRowspan(msDeclarationPrintViewForm.chemicalDetailList.length)">
                    化学物质：
                  </td>
                  <td class="ms-declare-print-item" :rowspan="computeRowspan(msDeclarationPrintViewForm.chemicalDetailList.length)">
                    <span v-if="msDeclarationPrintViewForm.distributionExp.existsChemical">有</span>
                    <span v-else-if="!msDeclarationPrintViewForm.distributionExp.existsChemical">无</span>
                  </td>
                  <td class="ms-declare-print-label">接触总人数：</td>
                  <td colspan="3" class="ms-declare-print-item">
                    {{ msDeclarationPrintViewForm.distributionExp.chemicalContacts }}
                  </td>
                </tr>
                <tr v-for="(item, index) in msDeclarationPrintViewForm.chemicalDetailList" v-if="index % 2 == 0">
                  <td class="ms-declare-print-label">{{ msDeclarationPrintViewForm.chemicalDetailList[index].hazardsName }}接触人数：</td>
                  <td class="ms-declare-print-item">
                    {{ msDeclarationPrintViewForm.chemicalDetailList[index].contactNumber }}
                  </td>
                  <td class="ms-declare-print-label">
                    <span v-if="msDeclarationPrintViewForm.chemicalDetailList[index + 1] != undefined">
                      {{ msDeclarationPrintViewForm.chemicalDetailList[index + 1].hazardsName + '接触人数：' }}
                    </span>
                  </td>
                  <td class="ms-declare-print-item">
                    <span v-if="msDeclarationPrintViewForm.chemicalDetailList[index + 1] != undefined">
                      {{ msDeclarationPrintViewForm.chemicalDetailList[index + 1].contactNumber }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="ms-declare-print-label" :rowspan="computeRowspan(msDeclarationPrintViewForm.physicalDetailList.length)">
                    物理因素：
                  </td>
                  <td class="ms-declare-print-item" :rowspan="computeRowspan(msDeclarationPrintViewForm.physicalDetailList.length)">
                    <span v-if="msDeclarationPrintViewForm.distributionExp.existsPhysical">有</span>
                    <span v-else-if="!msDeclarationPrintViewForm.distributionExp.existsPhysical">无</span>
                  </td>
                  <td class="ms-declare-print-label">接触总人数：</td>
                  <td class="ms-declare-print-item" colspan="3">
                    {{ msDeclarationPrintViewForm.distributionExp.physicalContacts }}
                  </td>
                </tr>
                <tr v-for="(item, index) in msDeclarationPrintViewForm.physicalDetailList" v-if="index % 2 == 0">
                  <td class="ms-declare-print-label">{{ msDeclarationPrintViewForm.physicalDetailList[index].hazardsName }}接触人数：</td>
                  <td class="ms-declare-print-item">
                    {{ msDeclarationPrintViewForm.physicalDetailList[index].contactNumber }}
                  </td>
                  <td class="ms-declare-print-label">
                    <span v-if="msDeclarationPrintViewForm.physicalDetailList[index + 1] != undefined">
                      {{ msDeclarationPrintViewForm.physicalDetailList[index + 1].hazardsName + '接触人数：' }}
                    </span>
                  </td>
                  <td class="ms-declare-print-item">
                    <span v-if="msDeclarationPrintViewForm.physicalDetailList[index + 1] != undefined">
                      {{ msDeclarationPrintViewForm.physicalDetailList[index + 1].contactNumber }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="ms-declare-print-label" :rowspan="computeRowspan(msDeclarationPrintViewForm.radioActivityDetailList.length)">
                    放射性因素：
                  </td>
                  <td class="ms-declare-print-item" :rowspan="computeRowspan(msDeclarationPrintViewForm.radioActivityDetailList.length)">
                    <span v-if="msDeclarationPrintViewForm.distributionExp.existsRadioactivity">有</span>
                    <span v-else-if="!msDeclarationPrintViewForm.distributionExp.existsRadioactivity">无</span>
                  </td>
                  <td class="ms-declare-print-label">接触总人数：</td>
                  <td class="ms-declare-print-item" colspan="3">
                    {{ msDeclarationPrintViewForm.distributionExp.radioactivityContacts }}
                  </td>
                </tr>
                <tr v-for="(item, index) in msDeclarationPrintViewForm.radioActivityDetailList" v-if="index % 2 == 0">
                  <td class="ms-declare-print-label">{{ msDeclarationPrintViewForm.radioActivityDetailList[index].hazardsName }}接触人数：</td>
                  <td class="ms-declare-print-item">
                    {{ msDeclarationPrintViewForm.radioActivityDetailList[index].contactNumber }}
                  </td>
                  <td class="ms-declare-print-label">
                    <span v-if="msDeclarationPrintViewForm.radioActivityDetailList[index + 1] != undefined">
                      {{ msDeclarationPrintViewForm.radioActivityDetailList[index + 1].hazardsName + '接触人数：' }}
                    </span>
                  </td>
                  <td class="ms-declare-print-item">
                    <span v-if="msDeclarationPrintViewForm.radioActivityDetailList[index + 1] != undefined">
                      {{ msDeclarationPrintViewForm.radioActivityDetailList[index + 1].contactNumber }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="ms-declare-print-label" :rowspan="computeRowspan(msDeclarationPrintViewForm.bioticDetailList.length)">
                    生物因素：
                  </td>
                  <td class="ms-declare-print-item" :rowspan="computeRowspan(msDeclarationPrintViewForm.bioticDetailList.length)">
                    <span v-if="msDeclarationPrintViewForm.distributionExp.existsBiotic">有</span>
                    <span v-else-if="!msDeclarationPrintViewForm.distributionExp.existsBiotic">无</span>
                  </td>
                  <td class="ms-declare-print-label">接触总人数：</td>
                  <td class="ms-declare-print-item" colspan="3">
                    {{ msDeclarationPrintViewForm.distributionExp.bioticContacts }}
                  </td>
                </tr>
                <tr v-for="(item, index) in msDeclarationPrintViewForm.bioticDetailList" v-if="index % 2 == 0">
                  <td class="ms-declare-print-label">{{ msDeclarationPrintViewForm.bioticDetailList[index].hazardsName }}接触人数：</td>
                  <td class="ms-declare-print-item">
                    {{ msDeclarationPrintViewForm.bioticDetailList[index].contactNumber }}
                  </td>
                  <td class="ms-declare-print-label">
                    <span v-if="msDeclarationPrintViewForm.bioticDetailList[index + 1] != undefined">
                      {{ msDeclarationPrintViewForm.bioticDetailList[index + 1].hazardsName + '接触人数：' }}
                    </span>
                  </td>
                  <td class="ms-declare-print-item">
                    <span v-if="msDeclarationPrintViewForm.bioticDetailList[index + 1] != undefined">
                      {{ msDeclarationPrintViewForm.bioticDetailList[index + 1].contactNumber }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="ms-declare-print-label" :rowspan="computeRowspan(msDeclarationPrintViewForm.otherDetailList.length)">
                    其他因素：
                  </td>
                  <td class="ms-declare-print-item" :rowspan="computeRowspan(msDeclarationPrintViewForm.otherDetailList.length)">
                    <span v-if="msDeclarationPrintViewForm.distributionExp.existsOther">有</span>
                    <span v-else-if="!msDeclarationPrintViewForm.distributionExp.existsOther">无</span>
                  </td>
                  <td class="ms-declare-print-label">接触总人数：</td>
                  <td class="ms-declare-print-item" colspan="3">
                    {{ msDeclarationPrintViewForm.distributionExp.otherContacts }}
                  </td>
                </tr>
                <tr v-for="(item, index) in msDeclarationPrintViewForm.otherDetailList" v-if="index % 2 == 0">
                  <td class="ms-declare-print-label">{{ msDeclarationPrintViewForm.otherDetailList[index].hazardsName }}接触人数：</td>
                  <td class="ms-declare-print-item">
                    {{ msDeclarationPrintViewForm.otherDetailList[index].contactNumber }}
                  </td>
                  <td class="ms-declare-print-label">
                    <span v-if="msDeclarationPrintViewForm.otherDetailList[index + 1] != undefined">
                      {{ msDeclarationPrintViewForm.otherDetailList[index + 1].hazardsName + '接触人数：' }}
                    </span>
                  </td>
                  <td class="ms-declare-print-item">
                    <span v-if="msDeclarationPrintViewForm.otherDetailList[index + 1] != undefined">
                      {{ msDeclarationPrintViewForm.otherDetailList[index + 1].contactNumber }}
                    </span>
                  </td>
                </tr>
              </table>
            </div>
            <div style="border-bottom: 1px solid #000000">
              <table class="ms-declare-detail-print-table">
                <tr>
                  <td class="ms-print-header" colspan="6">
                    职业病危害因素检测情况
                  </td>
                </tr>
                <tr>
                  <td width="120px" class="ms-declare-print-label print-center">
                    <div>年度检测情况：</div>
                  </td>
                  <td class="ms-declare-print-item" colspan="5">
                    <div>
                      <span v-if="msDeclarationPrintViewForm.detectionExp.existsDetection">已检测</span>
                      <span v-else-if="!msDeclarationPrintViewForm.detectionExp.existsDetection">未检测</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="120px" class="ms-declare-print-label">
                    <div>检测报告编号：</div>
                  </td>
                  <td colspan="5" class="ms-declare-print-item">
                    <div>
                      {{ msDeclarationPrintViewForm.declarationExp.delectionCode }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="ms-declare-print-label">
                    <div>检测机构名称：</div>
                  </td>
                  <td colspan="5" class="ms-declare-print-item">
                    <div>
                      {{ msDeclarationPrintViewForm.declarationExp.delectionOrganName }}
                    </div>
                  </td>
                </tr>

                <tr></tr>
                <tr>
                  <td class="ms-declare-print-label" :rowspan="msDeclarationPrintViewForm.dustDetailList.length + 1">
                    粉尘：
                  </td>
                  <td width="55px" class="ms-declare-print-item" :rowspan="msDeclarationPrintViewForm.dustDetailList.length + 1">
                    <span v-if="msDeclarationPrintViewForm.detectionExp.existsDust">检测</span>
                    <span v-else-if="!msDeclarationPrintViewForm.detectionExp.existsDust">未检测</span>
                  </td>
                  <td width="170px" class="ms-declare-print-label">检测点数：</td>
                  <td width="45px" class="ms-declare-print-item">
                    {{ msDeclarationPrintViewForm.detectionExp.dustPoints | dealNullOnly }}
                  </td>
                  <td width="170px" class="ms-declare-print-label">超标点数：</td>
                  <td width="45px" class="ms-declare-print-item">
                    {{ msDeclarationPrintViewForm.detectionExp.dustOverproofPoints | dealNullOnly }}
                  </td>
                </tr>
                <tr v-for="(item, index) in msDeclarationPrintViewForm.dustDetailList">
                  <td class="ms-declare-print-label">{{ item.hazardsName }}检测点数：</td>
                  <td class="ms-declare-print-item">{{ item.points | dealNullOnly }}</td>
                  <td class="ms-declare-print-label">{{ item.hazardsName }}超标点数：</td>
                  <td class="ms-declare-print-item">{{ item.overproofPoints | dealNullOnly }}</td>
                </tr>
                <tr>
                  <td class="ms-declare-print-label" :rowspan="msDeclarationPrintViewForm.chemicalDetailList.length + 1">
                    化学物质：
                  </td>
                  <td class="ms-declare-print-item" :rowspan="msDeclarationPrintViewForm.chemicalDetailList.length + 1">
                    <span v-if="msDeclarationPrintViewForm.detectionExp.existsChemical">检测</span>
                    <span v-else-if="!msDeclarationPrintViewForm.detectionExp.existsChemical">未检测</span>
                  </td>
                  <td class="ms-declare-print-label">检测点数：</td>
                  <td class="ms-declare-print-item">
                    {{ msDeclarationPrintViewForm.detectionExp.chemicalPoints | dealNullOnly }}
                  </td>
                  <td class="ms-declare-print-label">超标点数：</td>
                  <td class="ms-declare-print-item">
                    {{ msDeclarationPrintViewForm.detectionExp.chemicalOverproofPoints | dealNullOnly }}
                  </td>
                </tr>
                <tr v-for="(item, index) in msDeclarationPrintViewForm.chemicalDetailList">
                  <td class="ms-declare-print-label">{{ item.hazardsName }}检测点数：</td>
                  <td class="ms-declare-print-item">{{ item.points | dealNullOnly }}</td>
                  <td class="ms-declare-print-label">{{ item.hazardsName }}超标点数：</td>
                  <td class="ms-declare-print-item">{{ item.overproofPoints | dealNullOnly }}</td>
                </tr>
                <tr>
                  <td class="ms-declare-print-label" :rowspan="msDeclarationPrintViewForm.physicalDetailList.length + 1">
                    物理因素：
                  </td>
                  <td class="ms-declare-print-item" :rowspan="msDeclarationPrintViewForm.physicalDetailList.length + 1">
                    <span v-if="msDeclarationPrintViewForm.detectionExp.existsPhysical">检测</span>
                    <span v-else-if="!msDeclarationPrintViewForm.detectionExp.existsPhysical">未检测</span>
                  </td>
                  <td class="ms-declare-print-label">检测点数：</td>
                  <td class="ms-declare-print-item">
                    {{ msDeclarationPrintViewForm.detectionExp.physicalPoints | dealNullOnly }}
                  </td>
                  <td class="ms-declare-print-label">超标点数：</td>
                  <td class="ms-declare-print-item">
                    {{ msDeclarationPrintViewForm.detectionExp.physicalOverproofPoints | dealNullOnly }}
                  </td>
                </tr>
                <tr v-for="(item, index) in msDeclarationPrintViewForm.physicalDetailList">
                  <td class="ms-declare-print-label">{{ item.hazardsName }}检测点数：</td>
                  <td class="ms-declare-print-item">{{ item.points | dealNullOnly }}</td>
                  <td class="ms-declare-print-label">{{ item.hazardsName }}超标点数：</td>
                  <td class="ms-declare-print-item">{{ item.overproofPoints | dealNullOnly }}</td>
                </tr>
                <tr>
                  <td class="ms-declare-print-label" :rowspan="msDeclarationPrintViewForm.radioActivityDetailList.length + 1">
                    放射性因素：
                  </td>
                  <td class="ms-declare-print-item" :rowspan="msDeclarationPrintViewForm.radioActivityDetailList.length + 1">
                    <span v-if="msDeclarationPrintViewForm.detectionExp.existsRadioactivity">检测</span>
                    <span v-else-if="!msDeclarationPrintViewForm.detectionExp.existsRadioactivity">未检测</span>
                  </td>
                  <td class="ms-declare-print-label">检测点数：</td>
                  <td class="ms-declare-print-item">
                    {{ msDeclarationPrintViewForm.detectionExp.radioactivityPoints | dealNullOnly }}
                  </td>
                  <td class="ms-declare-print-label">超标点数：</td>
                  <td class="ms-declare-print-item">
                    {{ msDeclarationPrintViewForm.detectionExp.radioactivityOverproofPoints | dealNullOnly }}
                  </td>
                </tr>
                <tr v-for="(item, index) in msDeclarationPrintViewForm.radioActivityDetailList">
                  <td class="ms-declare-print-label">{{ item.hazardsName }}检测点数：</td>
                  <td class="ms-declare-print-item">{{ item.points | dealNullOnly }}</td>
                  <td class="ms-declare-print-label">{{ item.hazardsName }}超标点数：</td>
                  <td class="ms-declare-print-item">{{ item.overproofPoints | dealNullOnly }}</td>
                </tr>
                <tr>
                  <td class="ms-declare-print-label" :rowspan="msDeclarationPrintViewForm.bioticDetailList.length + msDeclarationPrintViewForm.otherDetailList.length + 1">
                    生物和其他因素：
                  </td>
                  <td class="ms-declare-print-item" :rowspan="msDeclarationPrintViewForm.bioticDetailList.length + msDeclarationPrintViewForm.otherDetailList.length + 1">
                    <span v-if="msDeclarationPrintViewForm.detectionExp.existsBioticOther">检测</span>
                    <span v-else-if="!msDeclarationPrintViewForm.detectionExp.existsBioticOther">未检测</span>
                  </td>
                  <td class="ms-declare-print-label">检测点数：</td>
                  <td class="ms-declare-print-item">
                    {{ msDeclarationPrintViewForm.detectionExp.bioticOtherPoints | dealNullOnly }}
                  </td>
                  <td class="ms-declare-print-label">超标点数：</td>
                  <td class="ms-declare-print-item">
                    {{ msDeclarationPrintViewForm.detectionExp.bioticOtherOverproofPoints | dealNullOnly }}
                  </td>
                </tr>
                <tr v-for="(item, index) in msDeclarationPrintViewForm.bioticDetailList">
                  <td class="ms-declare-print-label">{{ item.hazardsName }}检测点数：</td>
                  <td class="ms-declare-print-item">{{ item.points | dealNullOnly }}</td>
                  <td class="ms-declare-print-label">{{ item.hazardsName }}超标点数：</td>
                  <td class="ms-declare-print-item">{{ item.overproofPoints | dealNullOnly }}</td>
                </tr>
                <tr v-for="(item, index) in msDeclarationPrintViewForm.otherDetailList">
                  <td class="ms-declare-print-label">{{ item.hazardsName }}检测点数：</td>
                  <td class="ms-declare-print-item">{{ item.points | dealNullOnly }}</td>
                  <td class="ms-declare-print-label">{{ item.hazardsName }}超标点数：</td>
                  <td class="ms-declare-print-item">{{ item.overproofPoints | dealNullOnly }}</td>
                </tr>
              </table>
            </div>
            <div>
              <table class="ms-declare-detail-print-table">
                <tr>
                  <td class="ms-print-header" colspan="6">
                    职业健康监护开展情况
                  </td>
                </tr>
                <tr>
                  <td width="220px" class="ms-declare-print-label" colspan="2">
                    <div>年度岗中职业健康检查开展情况：</div>
                  </td>
                  <td class="ms-declare-print-item" colspan="4">
                    <div>
                      <span v-if="msDeclarationPrintViewForm.supervisionExp.existsSupervision">已开展</span>
                      <span v-else-if="!msDeclarationPrintViewForm.supervisionExp.existsSupervision">未开展</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="120px" class="ms-declare-print-label">
                    <div>检查总结报告编号：</div>
                  </td>
                  <td colspan="5" class="ms-declare-print-item">
                    <div>
                      {{ msDeclarationPrintViewForm.supervisionExp.existsSupervision ? msDeclarationPrintViewForm.declarationExp.supervisionCode : '/' }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="120px" class="ms-declare-print-label">
                    <div>检查机构名称：</div>
                  </td>
                  <td colspan="5" class="ms-declare-print-item ">
                    <div>
                      {{ msDeclarationPrintViewForm.supervisionExp.existsSupervision ? msDeclarationPrintViewForm.declarationExp.supervisionOrganName : '/' }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="ms-declare-print-label" :rowspan="computeRowspan(msDeclarationPrintViewForm.dustDetailList.length)">
                    粉尘：
                  </td>
                  <td class="ms-declare-print-item" width="60px" :rowspan="computeRowspan(msDeclarationPrintViewForm.dustDetailList.length)">
                    <span v-if="msDeclarationPrintViewForm.supervisionExp.existsDust">有</span>
                    <span v-else-if="!msDeclarationPrintViewForm.supervisionExp.existsDust">无</span>
                  </td>
                  <td class="ms-declare-print-label">体检总人数：</td>
                  <td class="ms-declare-print-item" colspan="3">
                    {{ msDeclarationPrintViewForm.supervisionExp.dustNumber | dealNull }}
                  </td>
                </tr>
                <tr v-for="(item, index) in msDeclarationPrintViewForm.dustDetailList" v-if="index % 2 == 0">
                  <td width="160px" class="ms-declare-print-label">{{ msDeclarationPrintViewForm.dustDetailList[index].hazardsName }}体检人数：</td>
                  <td width="45px" class="ms-declare-print-item">
                    {{ msDeclarationPrintViewForm.dustDetailList[index].peNumber | dealNull }}
                  </td>
                  <td width="160px" class="ms-declare-print-label">
                    <span v-if="msDeclarationPrintViewForm.dustDetailList[index + 1] != undefined">
                      {{ msDeclarationPrintViewForm.dustDetailList[index + 1].hazardsName + '体检人数：' }}
                    </span>
                  </td>
                  <td width="45px" class="ms-declare-print-item">
                    <span v-if="msDeclarationPrintViewForm.dustDetailList[index + 1] != undefined">
                      {{ msDeclarationPrintViewForm.dustDetailList[index + 1].peNumber | dealNull }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="ms-declare-print-label" :rowspan="computeRowspan(msDeclarationPrintViewForm.chemicalDetailList.length)">
                    化学物质：
                  </td>
                  <td class="ms-declare-print-item" :rowspan="computeRowspan(msDeclarationPrintViewForm.chemicalDetailList.length)">
                    <span v-if="msDeclarationPrintViewForm.supervisionExp.existsChemical">有</span>
                    <span v-else-if="!msDeclarationPrintViewForm.supervisionExp.existsChemical">无</span>
                  </td>
                  <td class="ms-declare-print-label">体检总人数：</td>
                  <td class="ms-declare-print-item" colspan="3">
                    {{ msDeclarationPrintViewForm.supervisionExp.chemicalNumber | dealNull }}
                  </td>
                </tr>
                <tr v-for="(item, index) in msDeclarationPrintViewForm.chemicalDetailList" v-if="index % 2 == 0">
                  <td class="ms-declare-print-label">{{ msDeclarationPrintViewForm.chemicalDetailList[index].hazardsName }}体检人数：</td>
                  <td class="ms-declare-print-item">
                    {{ msDeclarationPrintViewForm.chemicalDetailList[index].peNumber | dealNull }}
                  </td>
                  <td class="ms-declare-print-label">
                    <span v-if="msDeclarationPrintViewForm.chemicalDetailList[index + 1] != undefined">
                      {{ msDeclarationPrintViewForm.chemicalDetailList[index + 1].hazardsName + '体检人数：' }}
                    </span>
                  </td>
                  <td class="ms-declare-print-item">
                    <span v-if="msDeclarationPrintViewForm.chemicalDetailList[index + 1] != undefined">
                      {{ msDeclarationPrintViewForm.chemicalDetailList[index + 1].peNumber | dealNull }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="ms-declare-print-label" :rowspan="computeRowspan(msDeclarationPrintViewForm.physicalDetailList.length)">
                    物理因素：
                  </td>
                  <td class="ms-declare-print-item" :rowspan="computeRowspan(msDeclarationPrintViewForm.physicalDetailList.length)">
                    <span v-if="msDeclarationPrintViewForm.supervisionExp.existsPhysical">有</span>
                    <span v-else-if="!msDeclarationPrintViewForm.supervisionExp.existsPhysical">无</span>
                  </td>
                  <td class="ms-declare-print-label">体检总人数：</td>
                  <td class="ms-declare-print-item" colspan="3">
                    {{ msDeclarationPrintViewForm.supervisionExp.physicalNumber | dealNull }}
                  </td>
                </tr>
                <tr v-for="(item, index) in msDeclarationPrintViewForm.physicalDetailList" v-if="index % 2 == 0">
                  <td class="ms-declare-print-label">{{ msDeclarationPrintViewForm.physicalDetailList[index].hazardsName }}体检人数：</td>
                  <td class="ms-declare-print-item">
                    {{ msDeclarationPrintViewForm.physicalDetailList[index].peNumber | dealNull }}
                  </td>
                  <td class="ms-declare-print-label">
                    <span v-if="msDeclarationPrintViewForm.physicalDetailList[index + 1] != undefined">
                      {{ msDeclarationPrintViewForm.physicalDetailList[index + 1].hazardsName + '体检人数：' }}
                    </span>
                  </td>
                  <td class="ms-declare-print-item">
                    <span v-if="msDeclarationPrintViewForm.physicalDetailList[index + 1] != undefined">
                      {{ msDeclarationPrintViewForm.physicalDetailList[index + 1].peNumber | dealNull }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="ms-declare-print-label" :rowspan="computeRowspan(msDeclarationPrintViewForm.radioActivityDetailList.length)">
                    放射性因素：
                  </td>
                  <td class="ms-declare-print-item" :rowspan="computeRowspan(msDeclarationPrintViewForm.radioActivityDetailList.length)">
                    <span v-if="msDeclarationPrintViewForm.supervisionExp.existsRadioactivity">有</span>
                    <span v-else-if="!msDeclarationPrintViewForm.supervisionExp.existsRadioactivity">无</span>
                  </td>
                  <td class="ms-declare-print-label">体检总人数：</td>
                  <td class="ms-declare-print-item" colspan="3">
                    {{ msDeclarationPrintViewForm.supervisionExp.radioactivityNumber | dealNull }}
                  </td>
                </tr>
                <tr v-for="(item, index) in msDeclarationPrintViewForm.radioActivityDetailList" v-if="index % 2 == 0">
                  <td class="ms-declare-print-label">{{ msDeclarationPrintViewForm.radioActivityDetailList[index].hazardsName }}体检人数：</td>
                  <td class="ms-declare-print-item">
                    {{ msDeclarationPrintViewForm.radioActivityDetailList[index].peNumber }}
                  </td>
                  <td class="ms-declare-print-label">
                    <span v-if="msDeclarationPrintViewForm.radioActivityDetailList[index + 1] != undefined">
                      {{ msDeclarationPrintViewForm.radioActivityDetailList[index + 1].hazardsName + '体检人数：' }}
                    </span>
                  </td>
                  <td class="ms-declare-print-item">
                    <span v-if="msDeclarationPrintViewForm.radioActivityDetailList[index + 1] != undefined">
                      {{ msDeclarationPrintViewForm.radioActivityDetailList[index + 1].peNumber }}
                    </span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="print-date">
            <div>审核日期：</div>
            <div v-if="msDeclarationPrintViewForm.declarationExp.declareStatus == 3" style="width: 100px;">
              {{ msDeclarationPrintViewForm.approvalRecordExp.approveDate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import '@/assets/js/print'
export default {
  name: 'PrintHealth',
  props: {
    msDeclarationPrintViewForms: {
      type: Object,
      default: () => ({
        declarationExp: {},
        //主要产品
        majorProductList: [],
        //职业危害种类
        distributionExp: {},
        //职业危害因素检测情况
        detectionExp: {},
        //职业危害监护开展情况
        supervisionExp: {},
        //各个危害因素
        dustDetailList: [],
        chemicalDetailList: [],
        physicalDetailList: [],
        radioActivityDetailList: [],
        bioticDetailList: [],
        otherDetailList: [],
        //审批信息
        approvalRecordExp: {},
      }),
    },
  },
  filters: {
    dealNull: function(value) {
      if (value === null || value === undefined || value === '') return '/'
      return value + ''
    },
    dealNullOnly: function(value) {
      if (value === null || value === undefined || value === '') return '/'
      return value
    },
  },

  data() {
    return {
      dialogHeight: 420,
      msDeclarationPrintViewForm: {
        declarationExp: {},
        //主要产品
        majorProductList: [],
        //职业危害种类
        distributionExp: {},
        //职业危害因素检测情况
        detectionExp: {},
        //职业危害监护开展情况
        supervisionExp: {},
        //各个危害因素
        dustDetailList: [],
        chemicalDetailList: [],
        physicalDetailList: [],
        radioActivityDetailList: [],
        bioticDetailList: [],
        otherDetailList: [],
        //审批信息
        approvalRecordExp: {},
      },
    }
  },
  mounted() {
    this.dialogHeight = document.body.clientHeight * 0.7
    // 监听窗口大小变化
    window.onresize = function() {
      this.dialogHeight = document.body.clientHeight * 0.7
    }
  },
  methods: {
    assignment(from) {
      this.msDeclarationPrintViewForm = from
      this.$forceUpdate()
    },
    printApplyTable: function(from) {
      setTimeout(function() {
        Print('#myDeclareDetailPrint', {
          onStart: function() {
            console.log('onStart', new Date())
          },
          onEnd: function() {
            console.log('onEnd', new Date())
          },
        })
      }, 1000)
    },
    computeRowspan: function(size) {
      if (size % 2 === 0) {
        return size / 2 + 1
      } else {
        return size / 2 + 2
      }
    },
  },
}
</script>
<style lang="less" scoped>
.ms-card-title {
  height: 32px;
  line-height: 32px;
  margin-left: 15px;
  font-size: 12px;
  font-weight: 500;
  color: #475669;
}
.print-main-css {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: auto;
  width: 100%;
}

.ms-declare-detail-header {
  background: #f7fafc;
  height: 32px;
  line-height: 32px;
  color: #381e82;
  font-size: 12px;
  font-weight: bold;
  padding-left: 24px;
  position: relative;
}

.ms-declare-detail-header:before {
  content: '';
  width: 3px;
  height: 15px;
  position: absolute;
  top: 9px;
  left: 15px;
  background: #381e82;
}

.ms-declare-detail-table {
  border-top: 1px solid #d3dce6;
}

.ms-declare-detail-table .ms-declare-row {
  /*width: 1046px;*/
  display: flex;
  border-bottom: 1px solid #d3dce6;
  height: 32px;
  align-items: center;
}

.ms-declare-detail-table .ms-declare-row .ms-declare-col:last-child {
  border-right: none;
}

.ms-declare-detail-table .ms-declare-col {
  border-right: 1px solid #d3dce6;
  box-sizing: border-box;
}

.ms-declare-detail-table .ms-declare-label {
  width: 120px;
  font-size: 12px;
  text-align: right;
  height: 32px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.ms-declare-detail-table .ms-declare-label2 {
  width: 180px;
}

.ms-declare-detail-table .ms-declare-item {
  width: 130px;
  display: flex;
  font-size: 12px;
  height: 32px;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
}

.ms-declare-detail-table .ms-declare-item2 {
  width: 130px;
}

.ms-approve-remark textarea {
  margin: 10px;
  height: 112px;
}

.ms-approve-btn button {
  color: #0039b6;
  margin-right: 20px;
}

.ms-approve-remark-refuse,
.ms-approve-remark-pass {
  margin: 10px;
  font-size: 12px;
  line-height: 28px;
}

.ms-approve-remark-refuse button {
  color: #c7698b !important;
  margin-right: 5px;
}

.ms-approve-remark-pass button {
  color: #008000 !important;
  margin-right: 5px;
}

.ms-approve-divider {
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  padding: 0 20px;
  margin: 0 20px;
}

.ms-approve-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #029832;
  font-size: 16px;
  font-weight: 400;
}

.ms-approve-1 span {
  padding-left: 5px;
}

.print-border-none {
  border: none !important;
}

.ms-declare-detail-table-print1 .ms-declare-item {
  width: 100px !important;
}

.ms-declare-detail-table-print1 .ms-declare-label {
  /*width: 150px !important;*/
}

/**打印*/
.ms-declare-detail-print-table {
  width: 100%;
  border-spacing: 0;
  color: #000000;
}

.ms-declare-detail-print-table td {
  font-size: 12px;
  height: 32px;
  border-top: 1px solid #000000;
  border-right: 1px solid #000000;
}

.ms-declare-detail-print-table-1 td {
  font-size: 12px;
  height: 27px;
  border-top: 1px solid #000000;
  border-right: 1px solid #000000;
}

.ms-declare-detail-print-table .ms-declare-print-label {
  text-align: right;
}

.ms-declare-detail-print-table .ms-declare-print-label div {
  font-size: 12px;
  text-align: right;
  height: 32px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.ms-declare-detail-print-table-1 .ms-declare-print-label {
  text-align: right;
}

.ms-declare-detail-print-table-1 .ms-declare-print-label div {
  font-size: 12px;
  text-align: right;
  height: 27px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.ms-declare-detail-print-table .ms-declare-print-item {
  text-align: left;
  padding-left: 10px;
}

.ms-declare-detail-print-table .ms-declare-print-item div {
  display: flex;
  font-size: 12px;
  min-height: 32px;
  align-items: center;
  justify-content: flex-start;
}

.ms-declare-detail-print-table td:last-child {
  border-right: none !important;
}

.ms-declare-detail-print-table-1 .ms-declare-print-item {
  text-align: left;
  padding-left: 10px;
  width: 100px;
}

.ms-declare-detail-print-table-1 .ms-declare-print-item div {
  display: flex;
  font-size: 12px;
  min-height: 28px;
  align-items: center;
  justify-content: flex-start;
}

.ms-declare-detail-print-table-1 td:last-child {
  border-right: none !important;
}

.ms-card-print {
}

.ms-card-print-sub {
  border: 1px solid #000000;
}

.ms-card-print .el-card__header {
  border-bottom: 1px solid #000000 !important;
}

.print-border-bottom {
  color: #000000;
  border-bottom: 1px solid #000000;
}

.ms-declare-detail-table-print {
  border-bottom: 1px solid #000000 !important;
}

.ms-declare-detail-table-print .ms-declare-row {
  border-bottom: 1px solid #000000 !important;
}

.ms-declare-detail-table-print .ms-declare-col {
  color: #000000;
  border-right: 1px solid #000000 !important;
}

#myDeclareDetailPrint .ms-declare-detail-header {
  color: #000000 !important;
  border: 1px solid #000000 !important;
}

.ms-fc-right-1 {
  border-bottom: 1px solid #d3dce6;
}

.ms-hx {
  display: flex;
  flex-direction: row;
}

.ms-hx .ms-hx-1 {
  /*width: 1046px;*/
  display: flex;
  border-bottom: 1px solid #d3dce6;
  align-items: center;
}

.ms-hx .ms-hx-left-td-1 {
  border-right: 1px solid #d3dce6;
  box-sizing: border-box;
  width: 180px;
  font-size: 12px;
  text-align: right;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.ms-hx .ms-hx-left-td-2 {
  border-right: 1px solid #d3dce6;
  box-sizing: border-box;
  width: 130px;
  font-size: 12px;
  align-items: center;
  display: flex;
  padding-left: 10px;
}

.print-date {
  margin-top: 10px;
  display: flex;
  font-size: 12px;
  align-items: center;
  height: 32px;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 20px;
  color: #000000;
}

.ms-print-header {
  text-align: left;
  padding-left: 20px;
  height: 32px;
  line-height: 32px;
  font-size: 12px;
  font-weight: bold;
  border-top: none !important;
}

.print-center {
  text-align: center !important;
  padding-left: 0 !important;
}

.print-border-right-none {
  border-right: none !important;
}

.ms-declare-print-label {
  // width: 100px;
}

/*修改滚动条样式*/
div::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

div::-webkit-scrollbar-track {
  background: rgba(235, 236, 240, 1);
  border-radius: 0;
}

div::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 0;
}

div::-webkit-scrollbar-thumb:hover {
  background: #bfbfbf;
}

div::-webkit-scrollbar-corner {
  background: #179a16;
}

.print-div-occ-hazard .el-dialog__header {
  padding: 20px 20px 10px;
  background-color: #f5f7fb;
  font-size: 18px;
  font-weight: 800;
  color: #252525;
}

.print-div-occ-hazard .el-dialog__body {
  text-align: initial;
  padding: 0 32px 12px !important;
  background-color: #f5f7fb;
}

.print-div-occ-hazard .el-dialog__footer {
  padding: 10px 20px 20px;
  text-align: right;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #f5f7fb;
}
</style>
