<template>
  <div class="data_analysis">
    <base-tab :titles="tabTitles" v-model="activeName" @changeTab="activeChange"></base-tab>
    <!-- 机构分析 -->
    <div v-if="activeName == 1">
      <AgencyAnalysis @loading="showLoading"/>
    </div>
    <!-- 区域分析 -->
    <div v-if="activeName == 2">
      <RegionAnalysis @loading="showLoading"/>
    </div>
    <!-- 趋势分析 -->
    <div v-if="activeName == 3">
      <TrendAnalysis @loading="showLoading"/>
    </div>
     <!-- 行业维度 -->
     <div v-if="activeName == 4">
      <professionAnalysis @loading="showLoading"/>
    </div>
  </div>
</template>

<script>
import TrendAnalysis from './components/trendAnalysis'
import RegionAnalysis from './components/regionAnalysis'
import AgencyAnalysis from './components/agencyAnalysis.vue'
import professionAnalysis from './components/professionAnalysis.vue'
export default {
  name: 'DataAnalysisByYxh',
  components: { AgencyAnalysis,RegionAnalysis ,TrendAnalysis, professionAnalysis},
  data() {
    return {
      user:this.$store.state.users,
      tabTitles: ['机构分析','区域分析','趋势分析','行业分析'],
      activeName: '1',
    }
  },
  activated() { },
  mounted() {
  },
  methods: {
    activeChange(val) { },
    showLoading(status){
      this.$emit('loading',status)
    },
  },
}
</script>

<style lang="less" scoped></style>
