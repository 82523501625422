var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          { name: "show", rawName: "v-show", value: false, expression: "false" }
        ],
        staticClass: "print-main-css",
        style: "height: " + _vm.dialogHeight + "px"
      },
      [
        _c(
          "div",
          {
            staticStyle: {
              padding: "0 65px",
              width: "780px",
              "background-color": "white"
            },
            attrs: { id: "myDeclareDetailPrint" }
          },
          [
            _c("div", { staticClass: "ms-card-print" }, [
              _c(
                "div",
                {
                  staticStyle: {
                    "page-break-after": "always",
                    "padding-top": "30px"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "ms-card-print-sub",
                      staticStyle: {
                        "text-align": "center",
                        "border-bottom": "none"
                      },
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "ms-card-title",
                          staticStyle: {
                            color: "#000000",
                            "font-size": "16px",
                            "font-weight": "bold"
                          }
                        },
                        [_vm._v("职业病危害项目申报表")]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "ms-declare-detail ms-card-print-sub" },
                    [
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "border-bottom": "1px solid #000000"
                            }
                          },
                          [
                            _c(
                              "table",
                              { staticClass: "ms-declare-detail-print-table" },
                              [
                                _vm._m(0),
                                _c("tr", [
                                  _vm._m(1),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "ms-declare-print-item",
                                      attrs: { width: "80px" }
                                    },
                                    [
                                      _c("div", [
                                        _vm.msDeclarationPrintViewForm
                                          .declarationExp.declareType === 0
                                          ? _c("span", [_vm._v("初次申报")])
                                          : _vm.msDeclarationPrintViewForm
                                              .declarationExp.declareType === 1
                                          ? _c("span", [_vm._v("变更申报")])
                                          : _vm.msDeclarationPrintViewForm
                                              .declarationExp.declareType === 2
                                          ? _c("span", [_vm._v("年度更新")])
                                          : _vm._e()
                                      ])
                                    ]
                                  ),
                                  _vm._m(2),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "ms-declare-print-item",
                                      attrs: { width: "110px" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.msDeclarationPrintViewForm
                                              .declarationExp.declareYear
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._m(3),
                                  _c(
                                    "td",
                                    { staticClass: "ms-declare-print-item" },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.msDeclarationPrintViewForm
                                                .declarationExp.declareDate
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  )
                                ]),
                                _vm.msDeclarationPrintViewForm.declarationExp
                                  .declareType === 1 &&
                                _vm.msDeclarationPrintViewForm.declarationExp
                                  .reasonNo === "20030099"
                                  ? _c("tr", [
                                      _vm._m(4),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "ms-declare-print-item"
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.msDeclarationPrintViewForm
                                                    .declarationExp.reasonName
                                                ) +
                                                " "
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._m(5),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "ms-declare-print-item",
                                          attrs: { colspan: "3" }
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.msDeclarationPrintViewForm
                                                  .declarationExp.remark
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.msDeclarationPrintViewForm.declarationExp
                                  .declareType === 1 &&
                                _vm.msDeclarationPrintViewForm.declarationExp
                                  .reasonNo !== "20030099"
                                  ? _c("tr", [
                                      _vm._m(6),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "ms-declare-print-item",
                                          attrs: { colspan: "5" }
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.msDeclarationPrintViewForm
                                                    .declarationExp.reasonName
                                                ) +
                                                " "
                                            )
                                          ])
                                        ]
                                      )
                                    ])
                                  : _vm._e(),
                                _c("tr", [
                                  _vm._m(7),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "ms-declare-print-item",
                                      attrs: { colspan: "2" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.msDeclarationPrintViewForm
                                              .declarationExp.unitName
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._m(8),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "ms-declare-print-item",
                                      attrs: { colspan: "2" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.msDeclarationPrintViewForm
                                              .declarationExp.creditCode
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ]),
                                _c("tr", [
                                  _vm._m(9),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "ms-declare-print-item",
                                      attrs: { colspan: "5" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.msDeclarationPrintViewForm
                                              .declarationExp.regAddr
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ]),
                                _c("tr", [
                                  _vm._m(10),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "ms-declare-print-item",
                                      attrs: { colspan: "5" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.msDeclarationPrintViewForm
                                              .declarationExp.workAddr
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ]),
                                _c("tr", [
                                  _vm._m(11),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "ms-declare-print-item",
                                      attrs: { colspan: "2" }
                                    },
                                    [
                                      _c("div", [
                                        _vm.msDeclarationPrintViewForm
                                          .declarationExp.enterpriseScale === 1
                                          ? _c("span", [_vm._v("大型企业")])
                                          : _vm.msDeclarationPrintViewForm
                                              .declarationExp
                                              .enterpriseScale === 2
                                          ? _c("span", [_vm._v("中型企业")])
                                          : _vm.msDeclarationPrintViewForm
                                              .declarationExp
                                              .enterpriseScale === 3
                                          ? _c("span", [_vm._v("小型企业")])
                                          : _vm.msDeclarationPrintViewForm
                                              .declarationExp
                                              .enterpriseScale === 4
                                          ? _c("span", [_vm._v("微型企业")])
                                          : _vm._e()
                                      ])
                                    ]
                                  ),
                                  _vm._m(12),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "ms-declare-print-item",
                                      attrs: { colspan: "2" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.msDeclarationPrintViewForm
                                                .declarationExp.economicName
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  )
                                ]),
                                _c("tr", [
                                  _vm._m(13),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "ms-declare-print-item",
                                      attrs: { colspan: "5" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.msDeclarationPrintViewForm
                                                .declarationExp.industryCateName
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  )
                                ]),
                                _c("tr", [
                                  _vm._m(14),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "ms-declare-print-item",
                                      attrs: { colspan: "2" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.msDeclarationPrintViewForm
                                              .declarationExp.fillMan
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._m(15),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "ms-declare-print-item",
                                      attrs: { colspan: "2" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.msDeclarationPrintViewForm
                                              .declarationExp.fillPhone
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ]),
                                _c("tr", [
                                  _vm._m(16),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "ms-declare-print-item",
                                      attrs: { colspan: "2" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.msDeclarationPrintViewForm
                                              .declarationExp.legalPerson
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._m(17),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "ms-declare-print-item",
                                      attrs: { colspan: "2" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("dealNullOnly")(
                                              _vm.msDeclarationPrintViewForm
                                                .declarationExp.legalPersonPhone
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ]),
                                _c("tr", [
                                  _vm._m(18),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "ms-declare-print-item",
                                      attrs: { colspan: "2" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.msDeclarationPrintViewForm
                                              .declarationExp.linkManager
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._m(19),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "ms-declare-print-item",
                                      attrs: { colspan: "2" }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.msDeclarationPrintViewForm
                                              .declarationExp.linkPhone
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ]),
                                _c("tr", [
                                  _vm._m(20),
                                  _c(
                                    "td",
                                    { staticClass: "ms-declare-print-item" },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.msDeclarationPrintViewForm
                                              .declarationExp.empNum
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._m(21),
                                  _c(
                                    "td",
                                    { staticClass: "ms-declare-print-item" },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.msDeclarationPrintViewForm
                                              .declarationExp.externalNum
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._m(22),
                                  _c(
                                    "td",
                                    { staticClass: "ms-declare-print-item" },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.msDeclarationPrintViewForm
                                              .declarationExp.victimsNum -
                                              _vm.msDeclarationPrintViewForm
                                                .declarationExp.outSourcingNum
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ]),
                                _c("tr", [
                                  _vm._m(23),
                                  _c(
                                    "td",
                                    { staticClass: "ms-declare-print-item" },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.msDeclarationPrintViewForm
                                              .declarationExp.outSourcingNum
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._m(24),
                                  _c(
                                    "td",
                                    { staticClass: "ms-declare-print-item" },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.msDeclarationPrintViewForm
                                              .declarationExp.victimsNum
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._m(25),
                                  _c(
                                    "td",
                                    { staticClass: "ms-declare-print-item" },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.msDeclarationPrintViewForm
                                              .declarationExp
                                              .occupationalDiseasesNum
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ])
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "ms-declare-detail-print-table" },
                          [
                            _c(
                              "table",
                              {
                                staticClass:
                                  "ms-declare-detail-print-table print-border-bottom"
                              },
                              [
                                _vm._m(26),
                                _c("tr", [
                                  _vm._m(27),
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "ms-declare-print-item print-center",
                                      attrs: { width: "50px" }
                                    },
                                    [
                                      _vm.msDeclarationPrintViewForm
                                        .declarationExp.existsLeaderTrain
                                        ? _c("span", [_vm._v("已培训")])
                                        : _c("span", [_vm._v("未培训")])
                                    ]
                                  ),
                                  _vm._m(28),
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "ms-declare-print-item print-center",
                                      attrs: { width: "50px" }
                                    },
                                    [
                                      _vm.msDeclarationPrintViewForm
                                        .declarationExp.existsManagersTrain
                                        ? _c("span", [_vm._v("已培训")])
                                        : _c("span", [_vm._v("未培训")])
                                    ]
                                  )
                                ]),
                                _c("tr", [
                                  _vm._m(29),
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "ms-declare-print-item print-center"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.msDeclarationPrintViewForm
                                              .declarationExp.trainSum
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _vm._m(30),
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "ms-declare-print-item print-center"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.msDeclarationPrintViewForm
                                              .declarationExp.totalTraineeRate
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ])
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "ms-declare-detail-print-table" },
                          [
                            _c(
                              "table",
                              { staticClass: "ms-declare-detail-print-table" },
                              [
                                _vm._m(31),
                                _c("tr", [
                                  _vm._m(32),
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "ms-declare-print-item print-left",
                                      staticStyle: { width: "unset" },
                                      attrs: { colspan: "3" }
                                    },
                                    [
                                      _vm.msDeclarationPrintViewForm
                                        .declarationExp.ifClassifiedUnit
                                        ? _c("span", [_vm._v("是")])
                                        : _c("span", [_vm._v("否")])
                                    ]
                                  )
                                ]),
                                _vm._l(
                                  _vm.msDeclarationPrintViewForm
                                    .majorProductList,
                                  function(item) {
                                    return _c("tr", [
                                      _vm._m(33, true),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "ms-declare-print-item",
                                          staticStyle: { width: "180px" }
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(_vm._s(item.productName))
                                          ])
                                        ]
                                      ),
                                      _vm._m(34, true),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "ms-declare-print-item",
                                          staticStyle: { width: "unset" }
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(item.dosage) +
                                                " " +
                                                _vm._s(item.unit)
                                            )
                                          ])
                                        ]
                                      )
                                    ])
                                  }
                                ),
                                _vm.msDeclarationPrintViewForm.declarationExp
                                  .declareType === 1 &&
                                _vm.msDeclarationPrintViewForm
                                  .majorProductList !== undefined &&
                                _vm.msDeclarationPrintViewForm.majorProductList
                                  .length < 11
                                  ? [
                                      _vm._l(
                                        11 -
                                          _vm.msDeclarationPrintViewForm
                                            .majorProductList.length,
                                        function(count) {
                                          return [
                                            _c("tr", [
                                              _vm._m(35, true),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "ms-declare-print-item",
                                                  staticStyle: {
                                                    width: "180px"
                                                  }
                                                },
                                                [_vm._v("/")]
                                              ),
                                              _vm._m(36, true),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "ms-declare-print-item",
                                                  staticStyle: {
                                                    width: "unset"
                                                  }
                                                },
                                                [_vm._v("/")]
                                              )
                                            ])
                                          ]
                                        }
                                      )
                                    ]
                                  : _vm._e(),
                                _vm.msDeclarationPrintViewForm.declarationExp
                                  .declareType !== 1 &&
                                _vm.msDeclarationPrintViewForm
                                  .majorProductList !== undefined &&
                                _vm.msDeclarationPrintViewForm.majorProductList
                                  .length < 12
                                  ? [
                                      _vm._l(
                                        12 -
                                          _vm.msDeclarationPrintViewForm
                                            .majorProductList.length,
                                        function(count) {
                                          return [
                                            _c("tr", [
                                              _vm._m(37, true),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "ms-declare-print-item",
                                                  staticStyle: {
                                                    width: "180px"
                                                  }
                                                },
                                                [_vm._v("/")]
                                              ),
                                              _vm._m(38, true),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "ms-declare-print-item",
                                                  staticStyle: {
                                                    width: "unset"
                                                  }
                                                },
                                                [_vm._v("/")]
                                              )
                                            ])
                                          ]
                                        }
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          ]
                        )
                      ])
                    ]
                  )
                ]
              )
            ]),
            _c("div", { staticStyle: { "padding-top": "30px" } }, [
              _c(
                "div",
                { staticClass: "ms-declare-detail ms-card-print-sub" },
                [
                  _c(
                    "div",
                    { staticStyle: { "border-bottom": "1px solid #000000" } },
                    [
                      _c(
                        "table",
                        { staticClass: "ms-declare-detail-print-table" },
                        [
                          _vm._m(39),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-label",
                                attrs: {
                                  width: "95px",
                                  rowspan: _vm.computeRowspan(
                                    _vm.msDeclarationPrintViewForm
                                      .dustDetailList.length
                                  )
                                }
                              },
                              [_c("div", [_vm._v("粉尘：")])]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-item",
                                attrs: {
                                  width: "45px",
                                  rowspan: _vm.computeRowspan(
                                    _vm.msDeclarationPrintViewForm
                                      .dustDetailList.length
                                  )
                                }
                              },
                              [
                                _vm.msDeclarationPrintViewForm.distributionExp
                                  .existsDust
                                  ? _c("div", [_vm._v("有")])
                                  : !_vm.msDeclarationPrintViewForm
                                      .distributionExp.existsDust
                                  ? _c("div", [_vm._v("无")])
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-label",
                                attrs: { width: "170px" }
                              },
                              [_vm._v("接触总人数：")]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-item",
                                attrs: { colspan: "3" }
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.msDeclarationPrintViewForm
                                        .distributionExp.dustContacts
                                    )
                                  )
                                ])
                              ]
                            )
                          ]),
                          _vm._l(
                            _vm.msDeclarationPrintViewForm.dustDetailList,
                            function(item, index) {
                              return index % 2 == 0
                                ? _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "ms-declare-print-label",
                                        attrs: { width: "160px" }
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.msDeclarationPrintViewForm
                                                .dustDetailList[index]
                                                .hazardsName
                                            ) + "接触人数："
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "ms-declare-print-item",
                                        attrs: { width: "60px" }
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.msDeclarationPrintViewForm
                                                .dustDetailList[index]
                                                .contactNumber
                                            )
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "ms-declare-print-label",
                                        attrs: { width: "160px" }
                                      },
                                      [
                                        _vm.msDeclarationPrintViewForm
                                          .dustDetailList[index + 1] !=
                                        undefined
                                          ? _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm
                                                      .msDeclarationPrintViewForm
                                                      .dustDetailList[index + 1]
                                                      .hazardsName +
                                                      "接触人数："
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "ms-declare-print-item",
                                        attrs: { width: "60px" }
                                      },
                                      [
                                        _vm.msDeclarationPrintViewForm
                                          .dustDetailList[index + 1] !=
                                        undefined
                                          ? _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm
                                                      .msDeclarationPrintViewForm
                                                      .dustDetailList[index + 1]
                                                      .contactNumber
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            }
                          ),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-label",
                                attrs: {
                                  rowspan: _vm.computeRowspan(
                                    _vm.msDeclarationPrintViewForm
                                      .chemicalDetailList.length
                                  )
                                }
                              },
                              [_vm._v(" 化学物质： ")]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-item",
                                attrs: {
                                  rowspan: _vm.computeRowspan(
                                    _vm.msDeclarationPrintViewForm
                                      .chemicalDetailList.length
                                  )
                                }
                              },
                              [
                                _vm.msDeclarationPrintViewForm.distributionExp
                                  .existsChemical
                                  ? _c("span", [_vm._v("有")])
                                  : !_vm.msDeclarationPrintViewForm
                                      .distributionExp.existsChemical
                                  ? _c("span", [_vm._v("无")])
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "ms-declare-print-label" },
                              [_vm._v("接触总人数：")]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-item",
                                attrs: { colspan: "3" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.msDeclarationPrintViewForm
                                        .distributionExp.chemicalContacts
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]),
                          _vm._l(
                            _vm.msDeclarationPrintViewForm.chemicalDetailList,
                            function(item, index) {
                              return index % 2 == 0
                                ? _c("tr", [
                                    _c(
                                      "td",
                                      { staticClass: "ms-declare-print-label" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.msDeclarationPrintViewForm
                                              .chemicalDetailList[index]
                                              .hazardsName
                                          ) + "接触人数："
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "ms-declare-print-item" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.msDeclarationPrintViewForm
                                                .chemicalDetailList[index]
                                                .contactNumber
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "ms-declare-print-label" },
                                      [
                                        _vm.msDeclarationPrintViewForm
                                          .chemicalDetailList[index + 1] !=
                                        undefined
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm
                                                      .msDeclarationPrintViewForm
                                                      .chemicalDetailList[
                                                      index + 1
                                                    ].hazardsName + "接触人数："
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "ms-declare-print-item" },
                                      [
                                        _vm.msDeclarationPrintViewForm
                                          .chemicalDetailList[index + 1] !=
                                        undefined
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm
                                                      .msDeclarationPrintViewForm
                                                      .chemicalDetailList[
                                                      index + 1
                                                    ].contactNumber
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            }
                          ),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-label",
                                attrs: {
                                  rowspan: _vm.computeRowspan(
                                    _vm.msDeclarationPrintViewForm
                                      .physicalDetailList.length
                                  )
                                }
                              },
                              [_vm._v(" 物理因素： ")]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-item",
                                attrs: {
                                  rowspan: _vm.computeRowspan(
                                    _vm.msDeclarationPrintViewForm
                                      .physicalDetailList.length
                                  )
                                }
                              },
                              [
                                _vm.msDeclarationPrintViewForm.distributionExp
                                  .existsPhysical
                                  ? _c("span", [_vm._v("有")])
                                  : !_vm.msDeclarationPrintViewForm
                                      .distributionExp.existsPhysical
                                  ? _c("span", [_vm._v("无")])
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "ms-declare-print-label" },
                              [_vm._v("接触总人数：")]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-item",
                                attrs: { colspan: "3" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.msDeclarationPrintViewForm
                                        .distributionExp.physicalContacts
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]),
                          _vm._l(
                            _vm.msDeclarationPrintViewForm.physicalDetailList,
                            function(item, index) {
                              return index % 2 == 0
                                ? _c("tr", [
                                    _c(
                                      "td",
                                      { staticClass: "ms-declare-print-label" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.msDeclarationPrintViewForm
                                              .physicalDetailList[index]
                                              .hazardsName
                                          ) + "接触人数："
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "ms-declare-print-item" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.msDeclarationPrintViewForm
                                                .physicalDetailList[index]
                                                .contactNumber
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "ms-declare-print-label" },
                                      [
                                        _vm.msDeclarationPrintViewForm
                                          .physicalDetailList[index + 1] !=
                                        undefined
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm
                                                      .msDeclarationPrintViewForm
                                                      .physicalDetailList[
                                                      index + 1
                                                    ].hazardsName + "接触人数："
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "ms-declare-print-item" },
                                      [
                                        _vm.msDeclarationPrintViewForm
                                          .physicalDetailList[index + 1] !=
                                        undefined
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm
                                                      .msDeclarationPrintViewForm
                                                      .physicalDetailList[
                                                      index + 1
                                                    ].contactNumber
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            }
                          ),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-label",
                                attrs: {
                                  rowspan: _vm.computeRowspan(
                                    _vm.msDeclarationPrintViewForm
                                      .radioActivityDetailList.length
                                  )
                                }
                              },
                              [_vm._v(" 放射性因素： ")]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-item",
                                attrs: {
                                  rowspan: _vm.computeRowspan(
                                    _vm.msDeclarationPrintViewForm
                                      .radioActivityDetailList.length
                                  )
                                }
                              },
                              [
                                _vm.msDeclarationPrintViewForm.distributionExp
                                  .existsRadioactivity
                                  ? _c("span", [_vm._v("有")])
                                  : !_vm.msDeclarationPrintViewForm
                                      .distributionExp.existsRadioactivity
                                  ? _c("span", [_vm._v("无")])
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "ms-declare-print-label" },
                              [_vm._v("接触总人数：")]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-item",
                                attrs: { colspan: "3" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.msDeclarationPrintViewForm
                                        .distributionExp.radioactivityContacts
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]),
                          _vm._l(
                            _vm.msDeclarationPrintViewForm
                              .radioActivityDetailList,
                            function(item, index) {
                              return index % 2 == 0
                                ? _c("tr", [
                                    _c(
                                      "td",
                                      { staticClass: "ms-declare-print-label" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.msDeclarationPrintViewForm
                                              .radioActivityDetailList[index]
                                              .hazardsName
                                          ) + "接触人数："
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "ms-declare-print-item" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.msDeclarationPrintViewForm
                                                .radioActivityDetailList[index]
                                                .contactNumber
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "ms-declare-print-label" },
                                      [
                                        _vm.msDeclarationPrintViewForm
                                          .radioActivityDetailList[index + 1] !=
                                        undefined
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm
                                                      .msDeclarationPrintViewForm
                                                      .radioActivityDetailList[
                                                      index + 1
                                                    ].hazardsName + "接触人数："
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "ms-declare-print-item" },
                                      [
                                        _vm.msDeclarationPrintViewForm
                                          .radioActivityDetailList[index + 1] !=
                                        undefined
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm
                                                      .msDeclarationPrintViewForm
                                                      .radioActivityDetailList[
                                                      index + 1
                                                    ].contactNumber
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            }
                          ),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-label",
                                attrs: {
                                  rowspan: _vm.computeRowspan(
                                    _vm.msDeclarationPrintViewForm
                                      .bioticDetailList.length
                                  )
                                }
                              },
                              [_vm._v(" 生物因素： ")]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-item",
                                attrs: {
                                  rowspan: _vm.computeRowspan(
                                    _vm.msDeclarationPrintViewForm
                                      .bioticDetailList.length
                                  )
                                }
                              },
                              [
                                _vm.msDeclarationPrintViewForm.distributionExp
                                  .existsBiotic
                                  ? _c("span", [_vm._v("有")])
                                  : !_vm.msDeclarationPrintViewForm
                                      .distributionExp.existsBiotic
                                  ? _c("span", [_vm._v("无")])
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "ms-declare-print-label" },
                              [_vm._v("接触总人数：")]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-item",
                                attrs: { colspan: "3" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.msDeclarationPrintViewForm
                                        .distributionExp.bioticContacts
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]),
                          _vm._l(
                            _vm.msDeclarationPrintViewForm.bioticDetailList,
                            function(item, index) {
                              return index % 2 == 0
                                ? _c("tr", [
                                    _c(
                                      "td",
                                      { staticClass: "ms-declare-print-label" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.msDeclarationPrintViewForm
                                              .bioticDetailList[index]
                                              .hazardsName
                                          ) + "接触人数："
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "ms-declare-print-item" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.msDeclarationPrintViewForm
                                                .bioticDetailList[index]
                                                .contactNumber
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "ms-declare-print-label" },
                                      [
                                        _vm.msDeclarationPrintViewForm
                                          .bioticDetailList[index + 1] !=
                                        undefined
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm
                                                      .msDeclarationPrintViewForm
                                                      .bioticDetailList[
                                                      index + 1
                                                    ].hazardsName + "接触人数："
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "ms-declare-print-item" },
                                      [
                                        _vm.msDeclarationPrintViewForm
                                          .bioticDetailList[index + 1] !=
                                        undefined
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm
                                                      .msDeclarationPrintViewForm
                                                      .bioticDetailList[
                                                      index + 1
                                                    ].contactNumber
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            }
                          ),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-label",
                                attrs: {
                                  rowspan: _vm.computeRowspan(
                                    _vm.msDeclarationPrintViewForm
                                      .otherDetailList.length
                                  )
                                }
                              },
                              [_vm._v(" 其他因素： ")]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-item",
                                attrs: {
                                  rowspan: _vm.computeRowspan(
                                    _vm.msDeclarationPrintViewForm
                                      .otherDetailList.length
                                  )
                                }
                              },
                              [
                                _vm.msDeclarationPrintViewForm.distributionExp
                                  .existsOther
                                  ? _c("span", [_vm._v("有")])
                                  : !_vm.msDeclarationPrintViewForm
                                      .distributionExp.existsOther
                                  ? _c("span", [_vm._v("无")])
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "ms-declare-print-label" },
                              [_vm._v("接触总人数：")]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-item",
                                attrs: { colspan: "3" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.msDeclarationPrintViewForm
                                        .distributionExp.otherContacts
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]),
                          _vm._l(
                            _vm.msDeclarationPrintViewForm.otherDetailList,
                            function(item, index) {
                              return index % 2 == 0
                                ? _c("tr", [
                                    _c(
                                      "td",
                                      { staticClass: "ms-declare-print-label" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.msDeclarationPrintViewForm
                                              .otherDetailList[index]
                                              .hazardsName
                                          ) + "接触人数："
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "ms-declare-print-item" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.msDeclarationPrintViewForm
                                                .otherDetailList[index]
                                                .contactNumber
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "ms-declare-print-label" },
                                      [
                                        _vm.msDeclarationPrintViewForm
                                          .otherDetailList[index + 1] !=
                                        undefined
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm
                                                      .msDeclarationPrintViewForm
                                                      .otherDetailList[
                                                      index + 1
                                                    ].hazardsName + "接触人数："
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "ms-declare-print-item" },
                                      [
                                        _vm.msDeclarationPrintViewForm
                                          .otherDetailList[index + 1] !=
                                        undefined
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm
                                                      .msDeclarationPrintViewForm
                                                      .otherDetailList[
                                                      index + 1
                                                    ].contactNumber
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            }
                          )
                        ],
                        2
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticStyle: { "border-bottom": "1px solid #000000" } },
                    [
                      _c(
                        "table",
                        { staticClass: "ms-declare-detail-print-table" },
                        [
                          _vm._m(40),
                          _c("tr", [
                            _vm._m(41),
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-item",
                                attrs: { colspan: "5" }
                              },
                              [
                                _c("div", [
                                  _vm.msDeclarationPrintViewForm.detectionExp
                                    .existsDetection
                                    ? _c("span", [_vm._v("已检测")])
                                    : !_vm.msDeclarationPrintViewForm
                                        .detectionExp.existsDetection
                                    ? _c("span", [_vm._v("未检测")])
                                    : _vm._e()
                                ])
                              ]
                            )
                          ]),
                          _c("tr", [
                            _vm._m(42),
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-item",
                                attrs: { colspan: "5" }
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.msDeclarationPrintViewForm
                                          .declarationExp.delectionCode
                                      ) +
                                      " "
                                  )
                                ])
                              ]
                            )
                          ]),
                          _c("tr", [
                            _vm._m(43),
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-item",
                                attrs: { colspan: "5" }
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.msDeclarationPrintViewForm
                                          .declarationExp.delectionOrganName
                                      ) +
                                      " "
                                  )
                                ])
                              ]
                            )
                          ]),
                          _c("tr"),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-label",
                                attrs: {
                                  rowspan:
                                    _vm.msDeclarationPrintViewForm
                                      .dustDetailList.length + 1
                                }
                              },
                              [_vm._v(" 粉尘： ")]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-item",
                                attrs: {
                                  width: "55px",
                                  rowspan:
                                    _vm.msDeclarationPrintViewForm
                                      .dustDetailList.length + 1
                                }
                              },
                              [
                                _vm.msDeclarationPrintViewForm.detectionExp
                                  .existsDust
                                  ? _c("span", [_vm._v("检测")])
                                  : !_vm.msDeclarationPrintViewForm.detectionExp
                                      .existsDust
                                  ? _c("span", [_vm._v("未检测")])
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-label",
                                attrs: { width: "170px" }
                              },
                              [_vm._v("检测点数：")]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-item",
                                attrs: { width: "45px" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dealNullOnly")(
                                        _vm.msDeclarationPrintViewForm
                                          .detectionExp.dustPoints
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-label",
                                attrs: { width: "170px" }
                              },
                              [_vm._v("超标点数：")]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-item",
                                attrs: { width: "45px" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dealNullOnly")(
                                        _vm.msDeclarationPrintViewForm
                                          .detectionExp.dustOverproofPoints
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]),
                          _vm._l(
                            _vm.msDeclarationPrintViewForm.dustDetailList,
                            function(item, index) {
                              return _c("tr", [
                                _c(
                                  "td",
                                  { staticClass: "ms-declare-print-label" },
                                  [
                                    _vm._v(
                                      _vm._s(item.hazardsName) + "检测点数："
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticClass: "ms-declare-print-item" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dealNullOnly")(item.points)
                                      )
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticClass: "ms-declare-print-label" },
                                  [
                                    _vm._v(
                                      _vm._s(item.hazardsName) + "超标点数："
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticClass: "ms-declare-print-item" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dealNullOnly")(
                                          item.overproofPoints
                                        )
                                      )
                                    )
                                  ]
                                )
                              ])
                            }
                          ),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-label",
                                attrs: {
                                  rowspan:
                                    _vm.msDeclarationPrintViewForm
                                      .chemicalDetailList.length + 1
                                }
                              },
                              [_vm._v(" 化学物质： ")]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-item",
                                attrs: {
                                  rowspan:
                                    _vm.msDeclarationPrintViewForm
                                      .chemicalDetailList.length + 1
                                }
                              },
                              [
                                _vm.msDeclarationPrintViewForm.detectionExp
                                  .existsChemical
                                  ? _c("span", [_vm._v("检测")])
                                  : !_vm.msDeclarationPrintViewForm.detectionExp
                                      .existsChemical
                                  ? _c("span", [_vm._v("未检测")])
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "ms-declare-print-label" },
                              [_vm._v("检测点数：")]
                            ),
                            _c("td", { staticClass: "ms-declare-print-item" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dealNullOnly")(
                                      _vm.msDeclarationPrintViewForm
                                        .detectionExp.chemicalPoints
                                    )
                                  ) +
                                  " "
                              )
                            ]),
                            _c(
                              "td",
                              { staticClass: "ms-declare-print-label" },
                              [_vm._v("超标点数：")]
                            ),
                            _c("td", { staticClass: "ms-declare-print-item" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dealNullOnly")(
                                      _vm.msDeclarationPrintViewForm
                                        .detectionExp.chemicalOverproofPoints
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          ]),
                          _vm._l(
                            _vm.msDeclarationPrintViewForm.chemicalDetailList,
                            function(item, index) {
                              return _c("tr", [
                                _c(
                                  "td",
                                  { staticClass: "ms-declare-print-label" },
                                  [
                                    _vm._v(
                                      _vm._s(item.hazardsName) + "检测点数："
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticClass: "ms-declare-print-item" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dealNullOnly")(item.points)
                                      )
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticClass: "ms-declare-print-label" },
                                  [
                                    _vm._v(
                                      _vm._s(item.hazardsName) + "超标点数："
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticClass: "ms-declare-print-item" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dealNullOnly")(
                                          item.overproofPoints
                                        )
                                      )
                                    )
                                  ]
                                )
                              ])
                            }
                          ),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-label",
                                attrs: {
                                  rowspan:
                                    _vm.msDeclarationPrintViewForm
                                      .physicalDetailList.length + 1
                                }
                              },
                              [_vm._v(" 物理因素： ")]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-item",
                                attrs: {
                                  rowspan:
                                    _vm.msDeclarationPrintViewForm
                                      .physicalDetailList.length + 1
                                }
                              },
                              [
                                _vm.msDeclarationPrintViewForm.detectionExp
                                  .existsPhysical
                                  ? _c("span", [_vm._v("检测")])
                                  : !_vm.msDeclarationPrintViewForm.detectionExp
                                      .existsPhysical
                                  ? _c("span", [_vm._v("未检测")])
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "ms-declare-print-label" },
                              [_vm._v("检测点数：")]
                            ),
                            _c("td", { staticClass: "ms-declare-print-item" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dealNullOnly")(
                                      _vm.msDeclarationPrintViewForm
                                        .detectionExp.physicalPoints
                                    )
                                  ) +
                                  " "
                              )
                            ]),
                            _c(
                              "td",
                              { staticClass: "ms-declare-print-label" },
                              [_vm._v("超标点数：")]
                            ),
                            _c("td", { staticClass: "ms-declare-print-item" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dealNullOnly")(
                                      _vm.msDeclarationPrintViewForm
                                        .detectionExp.physicalOverproofPoints
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          ]),
                          _vm._l(
                            _vm.msDeclarationPrintViewForm.physicalDetailList,
                            function(item, index) {
                              return _c("tr", [
                                _c(
                                  "td",
                                  { staticClass: "ms-declare-print-label" },
                                  [
                                    _vm._v(
                                      _vm._s(item.hazardsName) + "检测点数："
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticClass: "ms-declare-print-item" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dealNullOnly")(item.points)
                                      )
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticClass: "ms-declare-print-label" },
                                  [
                                    _vm._v(
                                      _vm._s(item.hazardsName) + "超标点数："
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticClass: "ms-declare-print-item" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dealNullOnly")(
                                          item.overproofPoints
                                        )
                                      )
                                    )
                                  ]
                                )
                              ])
                            }
                          ),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-label",
                                attrs: {
                                  rowspan:
                                    _vm.msDeclarationPrintViewForm
                                      .radioActivityDetailList.length + 1
                                }
                              },
                              [_vm._v(" 放射性因素： ")]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-item",
                                attrs: {
                                  rowspan:
                                    _vm.msDeclarationPrintViewForm
                                      .radioActivityDetailList.length + 1
                                }
                              },
                              [
                                _vm.msDeclarationPrintViewForm.detectionExp
                                  .existsRadioactivity
                                  ? _c("span", [_vm._v("检测")])
                                  : !_vm.msDeclarationPrintViewForm.detectionExp
                                      .existsRadioactivity
                                  ? _c("span", [_vm._v("未检测")])
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "ms-declare-print-label" },
                              [_vm._v("检测点数：")]
                            ),
                            _c("td", { staticClass: "ms-declare-print-item" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dealNullOnly")(
                                      _vm.msDeclarationPrintViewForm
                                        .detectionExp.radioactivityPoints
                                    )
                                  ) +
                                  " "
                              )
                            ]),
                            _c(
                              "td",
                              { staticClass: "ms-declare-print-label" },
                              [_vm._v("超标点数：")]
                            ),
                            _c("td", { staticClass: "ms-declare-print-item" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dealNullOnly")(
                                      _vm.msDeclarationPrintViewForm
                                        .detectionExp
                                        .radioactivityOverproofPoints
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          ]),
                          _vm._l(
                            _vm.msDeclarationPrintViewForm
                              .radioActivityDetailList,
                            function(item, index) {
                              return _c("tr", [
                                _c(
                                  "td",
                                  { staticClass: "ms-declare-print-label" },
                                  [
                                    _vm._v(
                                      _vm._s(item.hazardsName) + "检测点数："
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticClass: "ms-declare-print-item" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dealNullOnly")(item.points)
                                      )
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticClass: "ms-declare-print-label" },
                                  [
                                    _vm._v(
                                      _vm._s(item.hazardsName) + "超标点数："
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticClass: "ms-declare-print-item" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dealNullOnly")(
                                          item.overproofPoints
                                        )
                                      )
                                    )
                                  ]
                                )
                              ])
                            }
                          ),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-label",
                                attrs: {
                                  rowspan:
                                    _vm.msDeclarationPrintViewForm
                                      .bioticDetailList.length +
                                    _vm.msDeclarationPrintViewForm
                                      .otherDetailList.length +
                                    1
                                }
                              },
                              [_vm._v(" 生物和其他因素： ")]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "ms-declare-print-item",
                                attrs: {
                                  rowspan:
                                    _vm.msDeclarationPrintViewForm
                                      .bioticDetailList.length +
                                    _vm.msDeclarationPrintViewForm
                                      .otherDetailList.length +
                                    1
                                }
                              },
                              [
                                _vm.msDeclarationPrintViewForm.detectionExp
                                  .existsBioticOther
                                  ? _c("span", [_vm._v("检测")])
                                  : !_vm.msDeclarationPrintViewForm.detectionExp
                                      .existsBioticOther
                                  ? _c("span", [_vm._v("未检测")])
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "ms-declare-print-label" },
                              [_vm._v("检测点数：")]
                            ),
                            _c("td", { staticClass: "ms-declare-print-item" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dealNullOnly")(
                                      _vm.msDeclarationPrintViewForm
                                        .detectionExp.bioticOtherPoints
                                    )
                                  ) +
                                  " "
                              )
                            ]),
                            _c(
                              "td",
                              { staticClass: "ms-declare-print-label" },
                              [_vm._v("超标点数：")]
                            ),
                            _c("td", { staticClass: "ms-declare-print-item" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dealNullOnly")(
                                      _vm.msDeclarationPrintViewForm
                                        .detectionExp.bioticOtherOverproofPoints
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          ]),
                          _vm._l(
                            _vm.msDeclarationPrintViewForm.bioticDetailList,
                            function(item, index) {
                              return _c("tr", [
                                _c(
                                  "td",
                                  { staticClass: "ms-declare-print-label" },
                                  [
                                    _vm._v(
                                      _vm._s(item.hazardsName) + "检测点数："
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticClass: "ms-declare-print-item" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dealNullOnly")(item.points)
                                      )
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticClass: "ms-declare-print-label" },
                                  [
                                    _vm._v(
                                      _vm._s(item.hazardsName) + "超标点数："
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticClass: "ms-declare-print-item" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dealNullOnly")(
                                          item.overproofPoints
                                        )
                                      )
                                    )
                                  ]
                                )
                              ])
                            }
                          ),
                          _vm._l(
                            _vm.msDeclarationPrintViewForm.otherDetailList,
                            function(item, index) {
                              return _c("tr", [
                                _c(
                                  "td",
                                  { staticClass: "ms-declare-print-label" },
                                  [
                                    _vm._v(
                                      _vm._s(item.hazardsName) + "检测点数："
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticClass: "ms-declare-print-item" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dealNullOnly")(item.points)
                                      )
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticClass: "ms-declare-print-label" },
                                  [
                                    _vm._v(
                                      _vm._s(item.hazardsName) + "超标点数："
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticClass: "ms-declare-print-item" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dealNullOnly")(
                                          item.overproofPoints
                                        )
                                      )
                                    )
                                  ]
                                )
                              ])
                            }
                          )
                        ],
                        2
                      )
                    ]
                  ),
                  _c("div", [
                    _c(
                      "table",
                      { staticClass: "ms-declare-detail-print-table" },
                      [
                        _vm._m(44),
                        _c("tr", [
                          _vm._m(45),
                          _c(
                            "td",
                            {
                              staticClass: "ms-declare-print-item",
                              attrs: { colspan: "4" }
                            },
                            [
                              _c("div", [
                                _vm.msDeclarationPrintViewForm.supervisionExp
                                  .existsSupervision
                                  ? _c("span", [_vm._v("已开展")])
                                  : !_vm.msDeclarationPrintViewForm
                                      .supervisionExp.existsSupervision
                                  ? _c("span", [_vm._v("未开展")])
                                  : _vm._e()
                              ])
                            ]
                          )
                        ]),
                        _c("tr", [
                          _vm._m(46),
                          _c(
                            "td",
                            {
                              staticClass: "ms-declare-print-item",
                              attrs: { colspan: "5" }
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.msDeclarationPrintViewForm
                                        .supervisionExp.existsSupervision
                                        ? _vm.msDeclarationPrintViewForm
                                            .declarationExp.supervisionCode
                                        : "/"
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          )
                        ]),
                        _c("tr", [
                          _vm._m(47),
                          _c(
                            "td",
                            {
                              staticClass: "ms-declare-print-item",
                              attrs: { colspan: "5" }
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.msDeclarationPrintViewForm
                                        .supervisionExp.existsSupervision
                                        ? _vm.msDeclarationPrintViewForm
                                            .declarationExp.supervisionOrganName
                                        : "/"
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          )
                        ]),
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "ms-declare-print-label",
                              attrs: {
                                rowspan: _vm.computeRowspan(
                                  _vm.msDeclarationPrintViewForm.dustDetailList
                                    .length
                                )
                              }
                            },
                            [_vm._v(" 粉尘： ")]
                          ),
                          _c(
                            "td",
                            {
                              staticClass: "ms-declare-print-item",
                              attrs: {
                                width: "60px",
                                rowspan: _vm.computeRowspan(
                                  _vm.msDeclarationPrintViewForm.dustDetailList
                                    .length
                                )
                              }
                            },
                            [
                              _vm.msDeclarationPrintViewForm.supervisionExp
                                .existsDust
                                ? _c("span", [_vm._v("有")])
                                : !_vm.msDeclarationPrintViewForm.supervisionExp
                                    .existsDust
                                ? _c("span", [_vm._v("无")])
                                : _vm._e()
                            ]
                          ),
                          _c("td", { staticClass: "ms-declare-print-label" }, [
                            _vm._v("体检总人数：")
                          ]),
                          _c(
                            "td",
                            {
                              staticClass: "ms-declare-print-item",
                              attrs: { colspan: "3" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dealNull")(
                                      _vm.msDeclarationPrintViewForm
                                        .supervisionExp.dustNumber
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _vm._l(
                          _vm.msDeclarationPrintViewForm.dustDetailList,
                          function(item, index) {
                            return index % 2 == 0
                              ? _c("tr", [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "ms-declare-print-label",
                                      attrs: { width: "160px" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.msDeclarationPrintViewForm
                                            .dustDetailList[index].hazardsName
                                        ) + "体检人数："
                                      )
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "ms-declare-print-item",
                                      attrs: { width: "45px" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("dealNull")(
                                              _vm.msDeclarationPrintViewForm
                                                .dustDetailList[index].peNumber
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "ms-declare-print-label",
                                      attrs: { width: "160px" }
                                    },
                                    [
                                      _vm.msDeclarationPrintViewForm
                                        .dustDetailList[index + 1] != undefined
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.msDeclarationPrintViewForm
                                                    .dustDetailList[index + 1]
                                                    .hazardsName + "体检人数："
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "ms-declare-print-item",
                                      attrs: { width: "45px" }
                                    },
                                    [
                                      _vm.msDeclarationPrintViewForm
                                        .dustDetailList[index + 1] != undefined
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("dealNull")(
                                                    _vm
                                                      .msDeclarationPrintViewForm
                                                      .dustDetailList[index + 1]
                                                      .peNumber
                                                  )
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                ])
                              : _vm._e()
                          }
                        ),
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "ms-declare-print-label",
                              attrs: {
                                rowspan: _vm.computeRowspan(
                                  _vm.msDeclarationPrintViewForm
                                    .chemicalDetailList.length
                                )
                              }
                            },
                            [_vm._v(" 化学物质： ")]
                          ),
                          _c(
                            "td",
                            {
                              staticClass: "ms-declare-print-item",
                              attrs: {
                                rowspan: _vm.computeRowspan(
                                  _vm.msDeclarationPrintViewForm
                                    .chemicalDetailList.length
                                )
                              }
                            },
                            [
                              _vm.msDeclarationPrintViewForm.supervisionExp
                                .existsChemical
                                ? _c("span", [_vm._v("有")])
                                : !_vm.msDeclarationPrintViewForm.supervisionExp
                                    .existsChemical
                                ? _c("span", [_vm._v("无")])
                                : _vm._e()
                            ]
                          ),
                          _c("td", { staticClass: "ms-declare-print-label" }, [
                            _vm._v("体检总人数：")
                          ]),
                          _c(
                            "td",
                            {
                              staticClass: "ms-declare-print-item",
                              attrs: { colspan: "3" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dealNull")(
                                      _vm.msDeclarationPrintViewForm
                                        .supervisionExp.chemicalNumber
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _vm._l(
                          _vm.msDeclarationPrintViewForm.chemicalDetailList,
                          function(item, index) {
                            return index % 2 == 0
                              ? _c("tr", [
                                  _c(
                                    "td",
                                    { staticClass: "ms-declare-print-label" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.msDeclarationPrintViewForm
                                            .chemicalDetailList[index]
                                            .hazardsName
                                        ) + "体检人数："
                                      )
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "ms-declare-print-item" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("dealNull")(
                                              _vm.msDeclarationPrintViewForm
                                                .chemicalDetailList[index]
                                                .peNumber
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "ms-declare-print-label" },
                                    [
                                      _vm.msDeclarationPrintViewForm
                                        .chemicalDetailList[index + 1] !=
                                      undefined
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.msDeclarationPrintViewForm
                                                    .chemicalDetailList[
                                                    index + 1
                                                  ].hazardsName + "体检人数："
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "ms-declare-print-item" },
                                    [
                                      _vm.msDeclarationPrintViewForm
                                        .chemicalDetailList[index + 1] !=
                                      undefined
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("dealNull")(
                                                    _vm
                                                      .msDeclarationPrintViewForm
                                                      .chemicalDetailList[
                                                      index + 1
                                                    ].peNumber
                                                  )
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                ])
                              : _vm._e()
                          }
                        ),
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "ms-declare-print-label",
                              attrs: {
                                rowspan: _vm.computeRowspan(
                                  _vm.msDeclarationPrintViewForm
                                    .physicalDetailList.length
                                )
                              }
                            },
                            [_vm._v(" 物理因素： ")]
                          ),
                          _c(
                            "td",
                            {
                              staticClass: "ms-declare-print-item",
                              attrs: {
                                rowspan: _vm.computeRowspan(
                                  _vm.msDeclarationPrintViewForm
                                    .physicalDetailList.length
                                )
                              }
                            },
                            [
                              _vm.msDeclarationPrintViewForm.supervisionExp
                                .existsPhysical
                                ? _c("span", [_vm._v("有")])
                                : !_vm.msDeclarationPrintViewForm.supervisionExp
                                    .existsPhysical
                                ? _c("span", [_vm._v("无")])
                                : _vm._e()
                            ]
                          ),
                          _c("td", { staticClass: "ms-declare-print-label" }, [
                            _vm._v("体检总人数：")
                          ]),
                          _c(
                            "td",
                            {
                              staticClass: "ms-declare-print-item",
                              attrs: { colspan: "3" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dealNull")(
                                      _vm.msDeclarationPrintViewForm
                                        .supervisionExp.physicalNumber
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _vm._l(
                          _vm.msDeclarationPrintViewForm.physicalDetailList,
                          function(item, index) {
                            return index % 2 == 0
                              ? _c("tr", [
                                  _c(
                                    "td",
                                    { staticClass: "ms-declare-print-label" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.msDeclarationPrintViewForm
                                            .physicalDetailList[index]
                                            .hazardsName
                                        ) + "体检人数："
                                      )
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "ms-declare-print-item" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("dealNull")(
                                              _vm.msDeclarationPrintViewForm
                                                .physicalDetailList[index]
                                                .peNumber
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "ms-declare-print-label" },
                                    [
                                      _vm.msDeclarationPrintViewForm
                                        .physicalDetailList[index + 1] !=
                                      undefined
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.msDeclarationPrintViewForm
                                                    .physicalDetailList[
                                                    index + 1
                                                  ].hazardsName + "体检人数："
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "ms-declare-print-item" },
                                    [
                                      _vm.msDeclarationPrintViewForm
                                        .physicalDetailList[index + 1] !=
                                      undefined
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("dealNull")(
                                                    _vm
                                                      .msDeclarationPrintViewForm
                                                      .physicalDetailList[
                                                      index + 1
                                                    ].peNumber
                                                  )
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                ])
                              : _vm._e()
                          }
                        ),
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "ms-declare-print-label",
                              attrs: {
                                rowspan: _vm.computeRowspan(
                                  _vm.msDeclarationPrintViewForm
                                    .radioActivityDetailList.length
                                )
                              }
                            },
                            [_vm._v(" 放射性因素： ")]
                          ),
                          _c(
                            "td",
                            {
                              staticClass: "ms-declare-print-item",
                              attrs: {
                                rowspan: _vm.computeRowspan(
                                  _vm.msDeclarationPrintViewForm
                                    .radioActivityDetailList.length
                                )
                              }
                            },
                            [
                              _vm.msDeclarationPrintViewForm.supervisionExp
                                .existsRadioactivity
                                ? _c("span", [_vm._v("有")])
                                : !_vm.msDeclarationPrintViewForm.supervisionExp
                                    .existsRadioactivity
                                ? _c("span", [_vm._v("无")])
                                : _vm._e()
                            ]
                          ),
                          _c("td", { staticClass: "ms-declare-print-label" }, [
                            _vm._v("体检总人数：")
                          ]),
                          _c(
                            "td",
                            {
                              staticClass: "ms-declare-print-item",
                              attrs: { colspan: "3" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dealNull")(
                                      _vm.msDeclarationPrintViewForm
                                        .supervisionExp.radioactivityNumber
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _vm._l(
                          _vm.msDeclarationPrintViewForm
                            .radioActivityDetailList,
                          function(item, index) {
                            return index % 2 == 0
                              ? _c("tr", [
                                  _c(
                                    "td",
                                    { staticClass: "ms-declare-print-label" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.msDeclarationPrintViewForm
                                            .radioActivityDetailList[index]
                                            .hazardsName
                                        ) + "体检人数："
                                      )
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "ms-declare-print-item" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.msDeclarationPrintViewForm
                                              .radioActivityDetailList[index]
                                              .peNumber
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "ms-declare-print-label" },
                                    [
                                      _vm.msDeclarationPrintViewForm
                                        .radioActivityDetailList[index + 1] !=
                                      undefined
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.msDeclarationPrintViewForm
                                                    .radioActivityDetailList[
                                                    index + 1
                                                  ].hazardsName + "体检人数："
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "ms-declare-print-item" },
                                    [
                                      _vm.msDeclarationPrintViewForm
                                        .radioActivityDetailList[index + 1] !=
                                      undefined
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.msDeclarationPrintViewForm
                                                    .radioActivityDetailList[
                                                    index + 1
                                                  ].peNumber
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                ])
                              : _vm._e()
                          }
                        )
                      ],
                      2
                    )
                  ])
                ]
              ),
              _c("div", { staticClass: "print-date" }, [
                _c("div", [_vm._v("审核日期：")]),
                _vm.msDeclarationPrintViewForm.declarationExp.declareStatus == 3
                  ? _c("div", { staticStyle: { width: "100px" } }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.msDeclarationPrintViewForm.approvalRecordExp
                              .approveDate
                          ) +
                          " "
                      )
                    ])
                  : _vm._e()
              ])
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "ms-print-header", attrs: { colspan: "6" } }, [
        _vm._v(" 基本信息 ")
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "td",
      { staticClass: "ms-declare-print-label", attrs: { width: "140px" } },
      [_c("div", [_vm._v("申报类型：")])]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "td",
      { staticClass: "ms-declare-print-label", attrs: { width: "110px" } },
      [_c("div", [_vm._v("申报年份：")])]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "td",
      { staticClass: "ms-declare-print-label", attrs: { width: "80px" } },
      [_c("div", [_vm._v("申报日期：")])]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "ms-declare-print-label" }, [
      _c("div", [_vm._v("变更原因：")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "ms-declare-print-label" }, [
      _c("div", [_vm._v("请注明：")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "ms-declare-print-label" }, [
      _c("div", [_vm._v("变更原因：")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "ms-declare-print-label" }, [
      _c("div", [_vm._v("单位名称：")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "ms-declare-print-label" }, [
      _c("div", [_vm._v("统一社会信用代码：")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "ms-declare-print-label" }, [
      _c("div", [_vm._v("单位注册地址：")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "ms-declare-print-label" }, [
      _c("div", [_vm._v("作业场所地址：")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "ms-declare-print-label" }, [
      _c("div", [_vm._v("企业规模：")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "ms-declare-print-label" }, [
      _c("div", [_vm._v("经济类型：")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "ms-declare-print-label" }, [
      _c("div", [_vm._v("行业分类：")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "ms-declare-print-label" }, [
      _c("div", [_vm._v("填报人：")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "ms-declare-print-label" }, [
      _c("div", [_vm._v("联系电话：")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "ms-declare-print-label" }, [
      _c("div", [_vm._v("法定代表人：")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "ms-declare-print-label" }, [
      _c("div", [_vm._v("联系电话：")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "ms-declare-print-label" }, [
      _c("div", [_vm._v("职业卫生管理联系人：")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "ms-declare-print-label" }, [
      _c("div", [_vm._v("手机号码：")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "ms-declare-print-label" }, [
      _c("div", [_vm._v("本单位在册职工总数：")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "ms-declare-print-label" }, [
      _c("div", [_vm._v("外委人员总数：")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "ms-declare-print-label" }, [
      _c("div", [_vm._v("在册接害人数：")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "ms-declare-print-label" }, [
      _c("div", [_vm._v("外委接害人数：")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "ms-declare-print-label" }, [
      _c("div", [_vm._v("接害总人数(含外委)：")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "ms-declare-print-label" }, [
      _c("div", [_vm._v("职业病累计人数：")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "ms-print-header", attrs: { colspan: "6" } }, [
        _vm._v(" 职业卫生培训情况 ")
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "td",
      { staticClass: "ms-declare-print-label", attrs: { width: "120px" } },
      [_c("div", [_vm._v("主要负责人培训：")])]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "td",
      { staticClass: "ms-declare-print-label", attrs: { width: "140px" } },
      [_c("div", [_vm._v("职业卫生管理人员培训：")])]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "td",
      { staticClass: "ms-declare-print-label", attrs: { width: "210px" } },
      [_c("div", [_vm._v("接触职业病危害因素年度培训总人数：")])]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "td",
      { staticClass: "ms-declare-print-label", attrs: { width: "210px" } },
      [_c("div", [_vm._v("接害人数培训率：")])]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "ms-print-header", attrs: { colspan: "4" } }, [
        _vm._v(" 主要产品 ")
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "td",
      {
        staticClass: "ms-declare-print-label",
        staticStyle: { width: "130px" }
      },
      [_c("div", [_vm._v("军工等涉密产品：")])]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "td",
      {
        staticClass: "ms-declare-print-label",
        staticStyle: { width: "130px" }
      },
      [_c("div", [_vm._v("产品名称：")])]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "td",
      { staticClass: "ms-declare-print-label", staticStyle: { width: "80px" } },
      [_c("div", [_vm._v("年产量：")])]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "td",
      {
        staticClass: "ms-declare-print-label",
        staticStyle: { width: "130px" }
      },
      [_c("div", [_vm._v("产品名称：")])]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "td",
      { staticClass: "ms-declare-print-label", staticStyle: { width: "80px" } },
      [_c("div", [_vm._v("年产量：")])]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "td",
      {
        staticClass: "ms-declare-print-label",
        staticStyle: { width: "130px" }
      },
      [_c("div", [_vm._v("产品名称：")])]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "td",
      { staticClass: "ms-declare-print-label", staticStyle: { width: "80px" } },
      [_c("div", [_vm._v("年产量：")])]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "ms-print-header", attrs: { colspan: "8" } }, [
        _vm._v(" 职业病危害因素种类 ")
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "ms-print-header", attrs: { colspan: "6" } }, [
        _vm._v(" 职业病危害因素检测情况 ")
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "td",
      {
        staticClass: "ms-declare-print-label print-center",
        attrs: { width: "120px" }
      },
      [_c("div", [_vm._v("年度检测情况：")])]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "td",
      { staticClass: "ms-declare-print-label", attrs: { width: "120px" } },
      [_c("div", [_vm._v("检测报告编号：")])]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "ms-declare-print-label" }, [
      _c("div", [_vm._v("检测机构名称：")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "ms-print-header", attrs: { colspan: "6" } }, [
        _vm._v(" 职业健康监护开展情况 ")
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "td",
      {
        staticClass: "ms-declare-print-label",
        attrs: { width: "220px", colspan: "2" }
      },
      [_c("div", [_vm._v("年度岗中职业健康检查开展情况：")])]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "td",
      { staticClass: "ms-declare-print-label", attrs: { width: "120px" } },
      [_c("div", [_vm._v("检查总结报告编号：")])]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "td",
      { staticClass: "ms-declare-print-label", attrs: { width: "120px" } },
      [_c("div", [_vm._v("检查机构名称：")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }