var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "data_analysis" },
    [
      _c("base-tab", {
        attrs: { titles: _vm.tabTitles },
        on: { changeTab: _vm.activeChange },
        model: {
          value: _vm.activeName,
          callback: function($$v) {
            _vm.activeName = $$v
          },
          expression: "activeName"
        }
      }),
      _vm.activeName == 1
        ? _c(
            "div",
            [_c("AgencyAnalysis", { on: { loading: _vm.showLoading } })],
            1
          )
        : _vm._e(),
      _vm.activeName == 2
        ? _c(
            "div",
            [_c("RegionAnalysis", { on: { loading: _vm.showLoading } })],
            1
          )
        : _vm._e(),
      _vm.activeName == 3
        ? _c(
            "div",
            [_c("TrendAnalysis", { on: { loading: _vm.showLoading } })],
            1
          )
        : _vm._e(),
      _vm.activeName == 4
        ? _c(
            "div",
            [_c("professionAnalysis", { on: { loading: _vm.showLoading } })],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }